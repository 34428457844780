import React from 'react';

interface CheckBoxProps {
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    title: string;
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
    return (
        <div className="form-check">
            <input
                type="checkbox"
                className="form-check-input"
                id={props.name}
                name={props.name}
                onChange={props.onChange}
                checked={props.checked}
            />
            <label className="form-check-label" htmlFor={props.name}>
                {props.title}
            </label>
        </div>
    );
};

export default CheckBox;
