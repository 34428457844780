import { useEffect, useState, useContext, ChangeEvent, FormEvent } from "react";
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { FaTrash, FaEdit } from "react-icons/fa";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/hr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { appContext, alertTypes } from '../../App';
import Input from "../form/Input";
import Checkbox from "../form/CheckBox";
import config from '../../config';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('hr');
dayjs.extend(advancedFormat)
dayjs().tz('Europe/Zagreb');

interface UserProfile {
    id: number;
    first_name: string;
    last_name: string;
    receive_newsletter: boolean;
}

const ProfilePage = () => {
    const [userProfile, setUserProfile] = useState<UserProfile>({
        id: 0,
        first_name: "",
        last_name: "",
        receive_newsletter: false,
    });
    const { jwtToken } = useOutletContext<{ jwtToken: string }>();
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const context = useContext(appContext);
    if (!context) {
        throw new Error("appContext must be used within an AppProvider");
    }
    const { toggleAlert, changeAlertType } = context;
    const { setAlertTitle, setAlertMessage } = useOutletContext<{ setAlertTitle: (title: string) => void, setAlertMessage: (message: string) => void }>();

    // get id from the URL
    let { id } = useParams<{ id: string }>();

    function loadUserProfile(jwtToken: string, navigate: ReturnType<typeof useNavigate>) {
        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions = {
            method: "GET",
            headers: headers,
            credentials: 'include' as RequestCredentials,
        }

        fetch(`${config.backendUrl}/admin/user-profile/${id}`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                setUserProfile(data.user_profile);
                console.log(data.user_profile);
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        loadUserProfile(jwtToken, navigate);
    }, [jwtToken, navigate]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions = {
            method: "POST",
            headers: headers,
            credentials: 'include' as RequestCredentials,
            body: JSON.stringify(userProfile),
        }

        fetch(`${config.backendUrl}/admin/update-profile`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Spremljeno!");
                    //setAlertMessage(data.message);
                }

                toggleAlert(true);
            })
            .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err.message);
                toggleAlert(true);
                console.log(err)
            });
    }

    const handleChange = (name: keyof UserProfile) => (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;

        setUserProfile({
            ...userProfile,
            [name]: value
        });
    }

    const hasError = (key: string) => {
        return errors.indexOf(key) !== -1;
    }

    const navigateTo = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, relativeUrl: string) => {
        event.preventDefault();
        navigate(relativeUrl);
    }

    return (
        <>
            <div className="col-md-4 offset-md-4">
                <br />
                {userProfile !== null ?
                    (
                        <>
                            <h2>Korisnički profil: {userProfile.first_name} {userProfile.last_name}</h2>
                            <hr />
                            <form onSubmit={handleSubmit}>
                                <input type="hidden" name="id" value={userProfile.id} id="id"></input>
                                <Input
                                    title={"Ime"}
                                    className={"form-control"}
                                    type={"text"}
                                    name={"first_name"}
                                    value={userProfile.first_name}
                                    onChange={handleChange("first_name")}
                                    errorDiv={hasError("first_name") ? "text-danger" : "d-none"}
                                    errorMsg={"Polje 'Ime' ne smije biti prazno."}
                                />
                                <Input
                                    title={"Prezime"}
                                    className={"form-control"}
                                    type={"text"}
                                    name={"last_name"}
                                    value={userProfile.last_name}
                                    onChange={handleChange("last_name")}
                                    errorDiv={hasError("last_name") ? "text-danger" : "d-none"}
                                    errorMsg={"Polje 'Prezime' ne smije biti prazno."}
                                />
                                <Checkbox
                                    title={"Želim primati mjesečni bilten (newsletter)"}
                                    name={"receive_newsletter"}
                                    checked={userProfile.receive_newsletter}
                                    onChange={handleChange("receive_newsletter")}
                                />
                                <hr />
                                <input
                                    type="submit"
                                    className="btn btn-primary"
                                    value="Spremi promjene"
                                />
                            </form>
                        </>
                    )
                    :
                    <span>Profil nije pronađen...</span>
                }
                <br />
                <br />
            </div>
            <br />
        </>
    );
}

export default ProfilePage;
