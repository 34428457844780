import { useEffect, useState, useContext, useCallback, MouseEvent } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { FaTrash, FaEdit } from "react-icons/fa";
import { appContext, alertTypes } from '../App';
import config from '../config';

interface Post {
    id: number;
    title: string;
    html: string;
}

const ManagePosts: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const { jwtToken } = useOutletContext<{ jwtToken: string }>();
    const navigate = useNavigate();
    const context = useContext(appContext);
    if (!context) {
        throw new Error("appContext must be used within a AppProvider");
    }
    const { toggleAlert, changeAlertType } = context;
    const { setAlertTitle } = useOutletContext<{ setAlertTitle: (title: string) => void }>();
    const { setAlertMessage } = useOutletContext<{ setAlertMessage: (message: string) => void }>();

    useEffect(() => {
        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
            credentials: 'include',
        };

        fetch(`${config.backendUrl}/admin/posts`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }
                return response.json();
            })
            .then((data) => {
                setPosts(data);
            })
            .catch(err => {
                console.log(err);
            });

    }, [jwtToken, navigate]);

    const deletePost = useCallback((postID: number) => {
        return async (event: MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            console.log('post delete...');
            console.log(postID);

            if (jwtToken === "") {
                navigate("/login");
                return;
            }
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${jwtToken}`);

            const requestOptions: RequestInit = {
                method: "GET",
                headers: headers,
                credentials: 'include',
            };

            fetch(`${config.backendUrl}/admin/delete-post/${postID}`, requestOptions)
                .then((response) => {
                    if (response.status === 401) {
                        console.log('Unauthorized');
                        navigate("/login");
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.error) {
                        changeAlertType(alertTypes.Danger);
                        setAlertTitle("Warning");
                        setAlertMessage(data.message);
                    } else {
                        changeAlertType(alertTypes.Success);
                        setAlertTitle("Post was successfully deleted!");
                        setAlertMessage(data.message);
                    }

                    toggleAlert(true);
                    setPosts([]);
                })
                .catch(err => {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Error");
                    setAlertMessage(err.message);
                    console.log(err);
                });
        }
    }, [jwtToken, navigate, changeAlertType, setAlertTitle, setAlertMessage, toggleAlert]);

    const renderPost = (post: Post) => {
        return { __html: post.html };
    };

    const navigateTo = (event: MouseEvent<HTMLAnchorElement>, relativeUrl: string) => {
        event.preventDefault();
        navigate(relativeUrl);
    };

    return (
        <>
            <div className="col-md-10 offset-md-1">
                <br />
                <h2>Edit Existing Posts</h2>
                <br />
                <br />
                <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                    {posts.length > 0 ? (posts.map((p) => (
                        <div key={p.id} className="col-md-3">
                            <div className="blog-entry">
                                <a className="img-link" onClick={(event) => navigateTo(event, `/admin/posts/${p.id}`)}>
                                    <img src="./../images/typewritter.jpg" alt="Image" className="img-fluid" />
                                </a>
                                <h4>{p.title}</h4>
                                <div className="row row-cols-2">
                                    <div className="col-md-6">
                                        <Link to={`/admin/posts/${p.id}`} className="btn btn-sm btn-outline-primary"><FaEdit /></Link>
                                        <Link to={`/admin/delete-post/${p.id}`} onClick={deletePost(p.id)} className="btn btn-sm btn-outline-primary"><FaTrash /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))) : (
                        <span>No posts found</span>
                    )}
                </div>
            </div>
            <br />
        </>
    );
}

export default ManagePosts;
