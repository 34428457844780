// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './components/Home';

import PostPage from './components/PostPage';
import PostsPage from './components/PostsPage';
import EventPage from './components/EventPage';
import EventsPage from './components/EventsPage';
import EcologyPage from './components/EcologyPage';
import InterestingPage from './components/InterestingPage';

import Login from './components/Login';
import RegistrationPage from './components/RegistrationPage';
import RequestResetPasswordLinkPage from './components/RequestResetPasswordLinkPage';
import App from './App';

import ErrorPage from './components/ErrorPage';
import BhaktiPage from './components/BhaktiPage';
import VegetarianismPage from './components/VegetarianismPage';
import SrilaPrabhupadaPage from './components/SrilaPrabhupadaPage';
import VedicKnowledgePage from './components/VedicKnowledgePage';
import ShopPage from './components/ShopPage';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import ManagePosts from './components/ManagePosts';
import EditPost from './components/EditPost';
import ManageEvents from './components/ManageEvents';
import EditEvent from './components/EditEvent';
import CreateEvent from './components/CreateEvent';
import UserProfilePage from './components/user/ProfilePage';
import FailedResetPaswordPage from "./components/FailedResetPaswordPage";
import ResetPasswordPage from "./components/ResetPasswordPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: "/bhakti",
        element: <BhaktiPage/>,
      },
      {
        path: "/vegetarianism",
        element: <VegetarianismPage/>,
      },
      {
        path: "/posts",
        element: <PostsPage/>,
      },
      {
        path: "/posts/:id",
        element: <PostPage/>,
      },
      {
        path: "/events",
        element: <EventsPage/>,
      },
      {
        path: "/events/:id",
        element: <EventPage/>,
      },
      {
        path: "/ecology",
        element: <EcologyPage/>,
      },
      // {
      //   path: "/interesting",
      //   element: <InterestingPage/>,
      // },
      {
        path: "/srila-prabhupada",
        element: <SrilaPrabhupadaPage/>,
      },
      {
        path: "/vedic-knowledge",
        element: <VedicKnowledgePage/>,
      },
      {
        path: "/shop",
        element: <ShopPage/>,
      },
      {
        path: "/about",
        element: <AboutPage/>,
      },
      {
        path: "/contact",
        element: <ContactPage/>,
      },
      {
        path: "/login",
        element: <Login/>,
      },
      {
        path: "/registration",
        element: <RegistrationPage/>,
      },
      {
        path: "/forgot-password",
        element: <RequestResetPasswordLinkPage/>,
      },
      {
        path: "/reset-password",
        element: <ResetPasswordPage/>,
      },
      {
        path: "/failed-reset-password",
        element: <FailedResetPaswordPage/>,
      },
      {
        path: "/admin/manage-posts",
        element: <ManagePosts />
      },
      {
        path: "/admin/add-post",
        element: <EditPost/>
      },
      {
        path: "/admin/create-event",
        element: <CreateEvent/>
      },
      {
        path: "/admin/manage-events",
        element: <ManageEvents />
      },
      {
        path: "/admin/events/:id",
        element: <EditEvent/>
      },
      {
        path: "/admin/posts/:id",
        element: <EditPost/>
      },
      {
        path: "/admin/user-profile/:id",
        element: <UserProfilePage/>
      },
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} /> 
  </React.StrictMode>
);
