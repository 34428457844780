import { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
//import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/hr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { decode } from 'html-entities';
import config from '../config';

dayjs.extend(utc);
// dayjs.extend(timezone);
dayjs.locale('hr');
dayjs.extend(advancedFormat)
// dayjs().tz('Europe/Zagreb');

interface Event {
    title: string;
    author: number;
    category: number;
    description: string;
    start_datetime: string;
    end_datetime: string;
    created_at: string;
    updated_at: string;
}

interface EventCategory {
    id: number;
    value: string;
    img: string;
}

const EventPage: React.FC = () => {
    const navigate = useNavigate();

    const [event, setEvent] = useState<Event>({
        title: "",
        author: 0,
        category: 0,
        description: "",
        start_datetime: "",
        end_datetime: "",
        created_at: "",
        updated_at: "",
    });

    const eventCategories: EventCategory[] = [
        {
            id: 1,
            value: "Bhagavad Gita",
            img: './../images/events/bg.jpg'
        },
        {
            id: 2,
            value: "Srimad Bhagavatam",
            img: './../images/events/sb.jpg'
        },
        {
            id: 3,
            value: "Kirtan večer",
            img: './../images/events/kirtan.jpg'
        },
        {
            id: 4,
            value: "Caitanya Caritamrta",
            img: './../images/events/cc.jpg'
        },
        {
            id: 5,
            value: "Posebna prigoda",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 6,
            value: "Festival",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 7,
            value: "Ostalo",
            img: './../images/events/byc-logo.png'
        },
    ];

    function getEventImage(event: Event): string {
        const result = eventCategories.find(({ id }) => id === event.category);
        return result?.img || '';
    }

    // get id from the URL
    let { id } = useParams<{ id: string }>();

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: headers,
        }

        fetch(`${config.backendUrl}/api/events/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setEvent(data.event);
            })
            .catch(err => {
                console.log(err);
            });
    }, [id]);

    function renderEvent() {
        return { __html: decode(event.description) };
    }

    function getEventDates(event: Event): string {
        const startDate = dayjs.utc(event.start_datetime).tz('Europe/Zagreb');
        const endDate = dayjs.utc(event.end_datetime).tz('Europe/Zagreb');
        const formattedStartDate = startDate.format('dddd, D. MMM, YYYY HH:mm');
        const formattedEndDate = endDate.format('dddd, D. MMM, YYYY HH:mm');

        if (startDate.isSame(endDate, 'date')) {
            return `${startDate.format('dddd, D. MMM')} (${startDate.format('HH:mm')} - ${endDate.format('HH:mm')})`;
        }

        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    const navigateTo = (event: React.MouseEvent<HTMLAnchorElement>, relativeUrl: string) => {
        event.preventDefault();
        navigate(relativeUrl);
    }

    return (
        <>
            <div className="container">
                <div className="half-content d-lg-flex align-items-stretch">
                    <div className="img aos-init aos-animate" style={{ backgroundImage: `url('${getEventImage(event)}')` }} data-aos="fade-in" data-aos-delay="100">
                    </div>
                    <div className="text">
                        <br />
                        <h2 className="heading mb-3">{event.title}, {getEventDates(event)}</h2>
                        <p className="mb-4"><div dangerouslySetInnerHTML={renderEvent()} /></p>
                        <br />
                        <p><a className="btn btn-outline-primary py-2" onClick={(event) => navigateTo(event, "/")} >{"<<<"}</a></p>
                        <br />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventPage;
