import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import CircularProgress from '@mui/material/CircularProgress';
import Posts from './Posts';
import Events from './Events';
import { Youtube } from 'react-bootstrap-icons';

const placeholder = {
    height: '20vh',
    backgroundImage: "url(./images/carousel/placeholder.png)",
    display: 'flex',
    alignItems: 'center', 
}

const circularProgress = {
    display: 'block',
    margin: '0px auto',
}

const carouselItem = {
    cursor: 'pointer',
}

interface CarouselItem {
    id: number;
    name: string;
    description: string;
    link: string;
    image: string;
}

const Home: React.FC = () => {
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();

    const navigateTo = (event: React.MouseEvent<HTMLImageElement>, relativeUrl: string) => {
        event.preventDefault();
        navigate(relativeUrl);
    }

    const items: CarouselItem[] = [
        {
            id: 0,
            name: "Vedsko znanje",
            description: "Probably the most random thing you have ever seen!",
            link: "/vedic-knowledge",
            image: "./images/carousel/vedic_knowledge.jpg"
        },
        {
            id: 1,
            name: "Vegetarijanstvo",
            description: "Probably the most random thing you have ever seen!",
            link: "/vegetarianism",
            image: "./images/carousel/vegetarianism.jpg"
        },
        {
            id: 2,
            name: "Bhakti yoga - yoga ljubavi",
            description: "Probably the most random thing you have ever seen!",
            link: "/bhakti",
            image: "./images/carousel/kirtan.jpg"
        },
        {
            id: 3,
            name: "Šrila Prabhupada",
            description: "Probably the most random thing you have ever seen!",
            link: "/srila-prabhupada",
            image: "./images/carousel/srila_prabhupada.jpg"
        },
        {
            id: 5,
            name: "Ekologija",
            description: "Probably the most random thing you have ever seen!",
            link: "/ecology",
            image: "./images/carousel/ecology.jpg"
        },
    ]

    useEffect(() => {
        setLoading(false);
    },[])

    return(
        <>
        <section className="section bg-light">
            <div className="container">
                <div className="row align-self-center retro-layout">
                {
                    (() => {
                        if(isLoading) {
                            return (
                                <div style={placeholder}>
                                    <CircularProgress style={circularProgress} />
                                </div>
                            )
                        } else {
                            return (
                                <Carousel interval={15000}>
                                    {
                                        items.map( (item) => 
                                            <Carousel.Item key={item.id} style={carouselItem}>
                                                <img
                                                    onClick={(event) => navigateTo(event, item.link)}
                                                    className="d-block w-100"
                                                    src={item.image}
                                                    alt={item.name}
                                                />
                                                <Carousel.Caption>
                                                    <h5>{item.name}</h5>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        )
                                    }
                                </Carousel>
                            )
                        }
                    })()  
                }
                </div>
                
            </div>
        </section>
        <section className="section posts-entry">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-sm-6">
                        <h5 className="posts-entry-title">Raspored događanja</h5>
                    </div>
                    <div className="col-sm-6 text-sm-end">
                        <Link to={`/events`}  className="btn btn-sm btn-outline-primary">Sva događanja</Link>
                    </div>
                </div>
                <Events/>
            </div>
        </section>
        <section className="section posts-entry posts-entry-sm bg-light">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-sm-6">
                        <h5 className="posts-entry-title"><Youtube color="red" size={26} /> Naš YouTube kanal</h5>
                        
                    </div>
                    <div className="col-sm-6 text-sm-end">
                        <Link to={`https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A`}  className="btn btn-sm btn-outline-primary">Otvori</Link>
                    </div>
                </div>
                <div className="row g-3">
                        <div className="col-md-4 videWrapper">
                            <iframe
                                src="https://www.youtube.com/embed?list=PLV1Ci4gdduxy7030hq5GTSsXElTKypARY&pp=gAQBiAQB" width="100%" height="400" >
                            </iframe>
                        </div>
                        <div className="col-md-4 videWrapper">
                            <iframe
                                src="https://www.youtube.com//embed?list=PLV1Ci4gdduxwR8rKb4Sz7v1nkn74sf1KS" width="100%" height="400">
                            </iframe>
                        </div>
                        <div className="col-md-4 videWrapper">
                            <iframe
                                src="https://www.youtube.com//embed?list=PLV1Ci4gdduxxRlr5kw33GdVBfXt9TUUIS" width="100%" height="400">
                            </iframe>
                        </div>
                </div>
            </div>
        </section>

        <section className="section posts-entry">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-sm-6">
                        <h5 className="posts-entry-title">Objave</h5>
                    </div>
                    <div className="col-sm-6 text-sm-end">
                        <Link to={`/posts`}  className="btn btn-sm btn-outline-primary">Sve objave</Link>
                    </div>

                </div>
               
                <Posts/>
            </div>
        </section>
        </>
    );
}

export default Home;
