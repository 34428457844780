import React, { FormEvent, useState, forwardRef, Ref } from "react";

interface CommentPayload {
    user_id: number;
    parent_comment_id: number;
    entity_type: string;
    entity_id: number;
    content: string;
}

interface CommentProps {
    parent_id: number;
    handleSubmit: (event:React.FormEvent<HTMLFormElement>, comment: string, parent_id:number) => JSX.Element | null;
    autoFocus?: boolean;
}

const LeaveComment = forwardRef<HTMLTextAreaElement, CommentProps>(({ parent_id, handleSubmit, autoFocus }, ref) => {
    return (
        <div className="comment-form-wrap pt-5">
            {/* <h3 className="mb-5">Leave a comment</h3> */}
            <form onSubmit={(event) => handleSubmit(event, (event.target as any).elements.message.value, parent_id)} className="p-5 bg-light">
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea id="message" ref={ref} cols={30} rows={10} className="form-control" autoFocus={autoFocus}></textarea>
                </div>
                <div className="form-group">
                    <input id={parent_id.toString()} type="submit" value="Comment" className="reply rounded" />
                </div>
            </form>
        </div>
    );
});

export default LeaveComment;




