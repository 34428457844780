import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { useState } from "react";

const headerImage: React.CSSProperties = {
    height: '40vh',
    backgroundImage: "url('./images/vegetarianism.jpg')",
};

const VegetarianismPage: React.FC = () => {

    const [expanded, setExpanded] = useState<boolean>(false);

    const handleExpandClick = (): void => { 
        setExpanded(!expanded);
    };

    return (
        <>
        <div className="site-cover site-cover-sm same-height overlay single-page" style={headerImage}>
        </div>
        <section className="section">
            <div className="container">

                <div className="row blog-entries element-animate">

                    <div className="col-md-12 col-lg-8 main-content">

                    <div className="post-content-body">
                        <br/>
                        <h1>Vegetarijanstvo</h1>
                        <br/>
                        <p>Postoji nekoliko vrlo dobrih razloga za to:
                        <ul>
                            <li>Svjetska ekonomija</li>
                            <li>Ekologija</li>
                            <li>Zdravlje</li>
                            <li>Moral/samilost</li>
                            <li>Duhovno–filozofski razlozi</li>
                        </ul>
                        U tekstu koji slijedi, koristio sam se uglavnom statističkim podacima Sjedinjenih Američkih Država, jer sam na Internetu našao nekoliko odličnih američkih web stranica posvećenih prednosti vegetarijanske ishrane.</p>
                        <br/>
                        <h4>Svjetska ekonomija</h4>
                        <hr/>
                        <small>[Tekst pod ovim podnaslovom je preuzet sa Interneta. Autorica: Mrs Rebecca Saltzberg. Naziv izvornog teksta: “The Steps to End World Hunger” / “Mjere za prestanak gladi u svijetu.”]</small>

                        <p>Postoji mnogo razloga zašto bi ljudi trebali prihvatiti vegetarijansku ishranu. Radi vlastitog zdravlja, zatim zdravlja i dobrostanja životinja, te radi zdravlja ovog našeg planeta. Međutim, još jedan vrlo važan razlog koji se često zanemaruje – radi dobrobiti milijuna ljudi koji svake godine umiru od gladi. Dok su bili djeca, mnogim ljudima su roditelji rekli da ‘počiste dobro svoj tanjur’ (pojedu sve) jer ‘u Africi ima puno gladne djece’. Još od rane mladosti su nas učili da je bacanje hrane grješno i okrutno pošto u svijetu ima puno nesretne, gladne djece. Pa ipak, naši dobronamjerni (ali pogrešno informirani) roditelji i odgajatelji nisu znali da ne postoji grubljeg razbacivanja hranom nego što je korištenje 13.5kg do 18kg zdravog žita (u obliku stočne hrane) kako bi se proizveo tek jedan kilogram životinjskog mesa. Oni su propustili razumjeti činjenicu da je najjednostavniji i najvažniji korak za svakoga od nas tko želi zaustaviti glad u svijetu – jednostavno postati vegetarijanac.</p>
                        <p>Danas u svijetu svake 2.3 sekunde jedno dijete umre zbog nepravilne (nedovoljne) ishrane. Neishranjenost je osnovni uzrok smrti novorođenčadi i djece u nerazvijenom zemljama. Zapravo, nikada ranije u ljudskoj povijesti glad nije tako harala čovječanstvom. Otprilike 25% stanovništva planeta Zemlje je neishranjeno. Da bi se zadovoljila potreba konzumiranja mesa i mesnih proizvoda – bezumno se rasipaju ograničena prirodna bogatstva. Uzgajanje životinja radi ljudske ishrane je užasno rasipno i neefikasno – jednostavno nije moguće hraniti čitavo čovječanstvo mesnom ishranom. Parcela zemlje koja je neophodna da nahrani jednog mesojeda – može prehraniti 20 vegetarijanaca. Količina žitarica i soje koja je neophodna da nahrani tovne životinje u SAD tijekom jedne godine – može prehraniti 1.3 milijuna gladnih ljudi. Stoka i ostale tovne životinje pojedu ogromnu količinu sveukupne svjetske proizvodnje žitarica. Slijedeći (loš) primjer SAD i ostalih industrijskih zemalja, mnoge zemlje u razvoju sve više i više prilagođuju svoju poljoprivredu proizvodnji mesa. Kao posljedica, zemlje trećega svijeta koje su nekada bile samodostatne u svojoj proizvodnji žitarica – sada ih moraju uvoziti iz SAD. I onda 75% od toga što uvezu – koriste kao hranu za tovne životinje.</p>
                        <div className="row my-4">
                        <div className="col-md-12 mb-4">
                            <img src="./images/veg/veg1.jpg" alt="Vegetarianism" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <img src="./images/veg/veg2.jpg" alt="Vegetarianism" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <img src="./images/veg/veg3.jpg" alt="Vegetarianism" className="img-fluid rounded" />
                        </div>
                        </div>
                        <p>Svaki pojedinac u svijetu može pridonijeti značajnoj promjeni. Kada samo jedna osoba postane vegetarijanac – rezultat je da se oslobodi dovoljno zemljišta koje može nahraniti 19 drugih ljudi.. Ako bi samo 10% Amerikanaca mesojeda, prihvatilo vegetarijanski način ishrane – to bi omogućilo 12 milijuna tona žitarica za ljudsku ishranu; dovoljno da prehrani 60 milijuna ljudi koji umiru od gladi svake godine.</p>
                        <ul>
                            <li>U skladu sa proračunom američkog odjeljenja za statistiku poljoprivrede (US Department of Agriculture) – 1 acre zemljišta (0.40469 hektara) može proizvesti 9060kg krumpira. To isto zemljište, kada se iskoristi za proizvodnju krmnog bilja – proizvede tek 74.7kg kravljeg mesa.</li>
                            <li>Stočarstvo i proizvodnja mesa (u SAD), odgovorni su a potrošnju polovine pitke vode koja se koristi u SAD.</li>
                            <li>Postotak kukuruza koji se u SAD koristi za ljudsku ishranu – 20%</li>
                            <li>Postotak kukuruza koji se u SAD koristi za ishranu stoke za klanje – 80%</li>
                            <li>Postotak ječma koji se u SAD koristi za ljudsku ishranu – 5%, a za stoku – 95%</li>
                            <li>Postotak proteina koji se izgubi u procesu od krmnog bilja (kukuruz, ječam, soja, itd.) do mesa – 90%</li>
                            <li>Postotak poljoprivrednih obradivih površina u SAD namijenjenih isključivo za proizvodnju govedine – 56%</li>
                            <li>Da bi se proizvela 1 lbs (0.453kg) žita, potrebno je utrošiti 95 litara vode; a da bi se proizvela ista količina govedine, potrebno je utrošiti 1900 litara vode.</li>
                            <li>Činjenica je da u svijetu ima dovoljno hrane za svakoga. Ali tragedija je u tome što se većina hrane i zemljišta u svijetu posvećuje proizvodnji stoke (mesa) za ljude u industrijski/tehnološki naprednim zemljama, dok milijuni djece i odraslih u ostalom dijelu svijeta pate i umiru zbog gladi i neuhranjenosti.</li>
                        </ul>
                        <br/>
                        <h4>Ekologija</h4>
                        <hr/>
                        <p>SAD uvoze svake godine oko 90 milijuna kg govedine iz Centralne Amerike. Otkuda toliko pašnjaka za ishranu sve te stoke? Odgovor: Krčenjem šuma i prašuma. U Brazilu, svake godine biva uništena površina prašume jednaka površini Belgije. Za svaki hamburger napravljen od krava uzgajanih na krčevinama gdje su nekada bile tropske šume Centralne i Latinske Amerike – uništeno je otprilike 5m2 šume. 2/3 tropskih šuma Centralne Amerike je iskrčeno samo za potrebe mesne industrije SAD – uglavnom za hamburgere koji završe u izvrsno organiziranom lancu multinacionalnih kompanija. Pošto čak ni to ne može zadovoljiti potrebe tržišta mesom – u SAD se više od 105 milijuna hektara šume iskrčilo radi potrebe stočarstva. Svatko od nas tko izabere vegetarijansku ishranu – spasi 0.40469 hektara šume svake godine.</p>
                        <p>Koje su posljedice masovnog krčenja šuma?</p>
                        <ul>
                            <li>
                                Pošto šume imaju sposobnost apsorbiranja viška CO2 (ugljičnog dioksida) u zraku, rapidno smanjenje površina svjetskih šuma dovodi do ubrzanog stvaranja ‘efekta staklenika’, tj. globalnog povećanje temperature. Neke od posljedica globalnog povećanja temperature su: a) podizanje razina mora i oceana (smanjuje se površina kopna, a povećava broj svjetskog stanovništva), b) pustinje se šire, i sve je manje obradivog zemljišta naročito u širokom pojasu oko ekvatora. Površina sušnih predjela se udvostručila od ‘70–tih godina. c) stvaranje razornih uragana.
                            </li>
                            <li>
                                Šume proizvode ozon i kisik neophodan za život. Masovno krčenje svjetskih šuma alarmantno smanjuje proizvodnju kisika.
                            </li>
                            <li>
                                Uništenje šumskih sela u kojima žive urođenici.
                            </li>
                            <li>
                                Uništenje prirodnog ambijenta u kojima žive mnoge vrste flore i faune. Trenutna stopa izumiranja biljnih i životinjskih vrsta radi krčenja tropskih šuma – oko 1000 vrsta godišnje.
                            </li>
                            <li>
                                Šume (uglavnom tropske) su izvor mnogih ljekovitih biljaka koje su jedini lijek za mnoge inače neizlječive bolesti.
                                <ul>
                                    <li>
                                        Vegetarijanska ishrana smanjuje proizvodnju ugljičnog dioksida (CO2). Prosječan automobil proizvede 3kg CO2 dnevno, dok napor neophodan za krčenje šume i proizvodnju mesa za samo jedan hamburger – proizvodi 75kg CO2. Druga dobrobit vegetarijanske ishrane je ta da pridonosi smanjenju ulova ribe (pražnjenje mora i oceana) te smanjenju uništenja koraljnih grebena.
                                    </li>
                                    <li>
                                        Čak i kada svi ljudi u svijetu ne bi postali vegetarijanci, ali da prestanu jesti crveno meso i smanje konzumiranje ribe, piletine i jaja za 50–70% – to bi napravilo ogromnu pozitivnu promjenu u životu na planetu Zemlji. Stočarske farme bi se morale zatvoriti. Više ne bi bilo potrebe za krčenjem šuma radi pašnjaka za stoku. A zemljište namijenjeno uzgoju krmnog bilja bilo bi iskorišteno za uzgoj kultura namijenjenih direktnoj ljudskoj ishrani (čime bi 20 puta više ljudi bilo opskrbljeno kvalitetnom ishranom). To bi za uzvrat zaustavilo glad u svijetu, pridonijelo jačanju ljudskog zdravlja, smanjilo potrošnju fosilnih goriva (odgovornih za globalno povećanje temperature), itd. Međutim, ako svi ljudi na svijetu ipak postali vegetarijanci, sadašnja stopa proizvodnje hrane bi mogla prehraniti 10 milijardi ljudi. (Population Reference Bureau).
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <br/>
                        <h4>Zdravlje</h4>
                        <hr/>
                        <ul>
                            <li>Vegetarijanci imaju 14% manju razinu krvnog kolesterola nego mesojedi.</li>
                            <li>U odnosu na mesojede – vegetarijanci imaju 2.3 puta manji rizik od bolesti srca.</li>
                            <li>U prosjeku, od četiri osobe sa previsokim krvnim pritiskom – tri su mesojedi.</li>
                            <li>Prekomjerna upotreba antibiotika u stočarstvu, peradarstvu, itd. je jedan od osnovnih faktora odgovoran za razvoj čitave armije novih patogena protiv kojih moderna znanost nema lijeka.</li>
                            <li>Kontaminacija mlijeka za dojenje zbog kloriziranog ugljikovodika (pesticid koji se nalazi u mesu i mesnim prerađevinama) kod dojilja koje jedu meso je 35 puta veća nego kod dojilja koje su vegetarijanke.</li>
                            <li>1961. godine ‘Journal of the American Medical Association’ je izjavio: “90%–97% bolesti srca može biti spriječeno vegetarijanskom ishranom.”</li>
                            <li>Meso sadrži 14 puta više pesticida i ostalih kemikalija nego biljna hrana.</li>
                            <li>Mesna ishrana potiče agresivnost, nervozu i (u nekim slučajevima) depresiju – zbog adrenalina koji se nalazi u mesu životinje koja je umirala u agoniji.</li>
                            <li>Vegetarijanska ishrana može spriječiti pojavu mnogih bolesti. U skladu sa podacima kojima raspolaže Američko Dijetetsko Udruženje (American Dietetic Association) – vegetarijanci (u odnosu na mesojede) imaju uveliko smanjen rizik od srčanih bolesti, prekomjerne tjelesne težine, raka crijeva, diabetesa, osteoporoze, žučnih kamenaca, bubrežnih kamenaca, raka pluća i raka dojki.</li>
                        </ul>
                        <h5>- Anatomija -</h5>
                        <p>Biolozi su zaključili i dokazali da životinje koje dijele iste tjelesne (anatomske) karakteristike, također dijele i istu vrstu prehrane. Uspoređujući anatomiju životinja mesoždera sa našom vlastitom, možemo jasno vidjeti da ljudi nisu stvoreni kao mesožderi jer imaju anatomiju gotovo istovjetnu životinjama biljojedima. Pri tome je važno napomenuti da životinje svežderi (kao medvjed), imaju tjelesnu anatomiju vrlo blisku životinjama mesožderima.</p>
                        <ul>
                            <li>Za razliku od mesoždera, ljudi mogu pokretati donju čeljust lijevo–desno baš kao i biljojedi.</li>
                            <li>Mesožderi ne žvaču hranu, nego je gutaju. Biljojedi i čovjek žvaču hranu. Mesožderi nemaju potrebe za kuhanjem, prženjem i pečenjem mesa kako bi ga mogli svariti. Ako su ljudi zaista stvoreni da jedu meso, zašto ga ne love i jedu sirovo baš kao što to čine mesožderi?</li>
                            <li>Mesožderi imaju kiselu pljuvačku, bez ugljikovodika za probavu enzima. Biljojedi i čovjek imaju alkalnu pljuvačku sa ugljikovodicima.</li>
                            <li>Želučana kiselina mesoždera je puno jača od želučane kiseline biljojeda i čovjeka – zbog toga što mesožderi moraju probaviti kosti i krupne komade neprožvakanog mesa, što nije slučaj kod biljojeda. Želučana kiselina mesoždera je dovoljno jaka da ubije bakterije (koje se nalaze u mesu) koje mogu ozbiljno razboliti ili čak ubiti vegetarijance. Alkalna vrijednost želučane kiseline mesoždera je pH 1, dok je kod biljojeda i čovjeka pH 4 do 5.</li>
                            <li>Dužina crijeva kod mesoždera je 3 do 5 x dužina tijela, a kod biljojeda i čovjeka 10 do 12 x dužina tijela. Razlog tome je što mesožderi moraju što brže izbaciti iz tijela toksine i kancerogene tvari koji se nalaze u mesu. (Meso i riba sadrže kancerogene tvari koje napadaju genetički materijal DNK stanica i razvijaju rak).</li>
                            <li>Mesožderi se znoje preko jezika i nemaju kožnih pora, dok se biljojedi i čovjek znoje preko kože i imaju pore.</li>
                            <li>Zubi mesoždera su oblikovani isključivo za kidanje mesa i kostiju (zbog čega su oštri i šiljasti). Zubi biljojeda su pljosnati baš kao i ljudski, sa izuzetkom očnjaka koji služe za lakše žvakanje biljnih vlakana. Mnogi ljudi pogrešno misle da su očnjaci kod čovjeka dokaz da su ljudi evoluirali od majmuna koji su se hranili mesom. Ako bismo slijedili istu pogrešnu logiku, mogli bismo zaključiti da su bradavice na muškim prsima ‘dokaz’ da su i muškarci u dalekoj prošlosti imali dojke i dojili djecu. Besmisleno, zar ne?</li>
                        </ul>
                        <h5>- Mit o proteinu -</h5>
                        <p>Izazov broj jedan koji se postavlja vegetarijancima je: “Vegetarijanska ishrana ne opskrbljuje s dovoljno proteina za pravilno funkcioniranje organizma!” Ništa u modernoj ljudskoj ishrani nije tako pogrešno protumačeno kao protein. Važnost unošenja dovoljno proteina u organizam, uglavnom iz mesa i mesnih prerađevina – nabija nam se u glavu još od ranog djetinjstva. Izvrsan izvor biljnog proteina su: orašidi, sjemenke, grahorice, žitarice, proizvodi od soje (naročito seitan) i mliječni proizvodi. Čak i neke vrste voća i povrća imaju nezanemarivi postotak proteina u sebi.</p>
                        <p>Prevelika doza proteina može biti jednako štetna baš kao i nedostatak proteina. Prekomjernost proteina u hrani usko je povezano sa kraćim životnim vijekom, povećanim rizikom od bilo koje vrste raka, bolestima srca, prekomjernom tjelesnom težinom, dijabetesom, osteoporozom, nepravilnim radom bubrega i lošom probavom (koja je uzročni korijen mnogih drugih bolesti).</p>
                        <p>Biti vegetarijanac ne znači nedovoljno unošenje proteina u organizam. Uravnoteženom vegetarijanskom ishranom gotovo je nemoguće izbjeći neophodnu količinu proteina. Ukoliko u hrani nema dovoljno kalorija, organizam koristi proteine kao izvor energije, radije nego li za rast i popravljanje grešaka u metabolizmu. Međutim, to nije problem za vegetarijance jer biljni proteini su odlični izvori ugljikovodika, koje organizam koristi kao izvor energije. Dokaz za to? Najveće, najizdržljivije i najjače životinje na svijetu su upravo biljojedi (vegetarijanci): slon, nosorog, žirafa, bizon, bik, kamila, orangutan, gorila, itd. (Da. I gorila je vegetarijanac usprkos svojim impresivnim očnjacima koji mu služe za kidanje i žvakanje drvenih vlakana).</p>
                        <p>Mnogi vrhunski sportaši vegetarijanci su dokazali da je vegetarijanska ishrana izvrsna i za ekstra napore: 1) Bill Pearl – Mr. Universe/body building, 2) Andreas Cahling – Mr. International/ body building, 3) Al Beckles – najistaknutiji body builder ‘60–tih godina, 4) Carl Lewis – atletičar, 5) Edwin Moses – svjetski rekorder u disciplini trčanja 400m sa preponama, 6) Killer Kowalski – svjetski šampion u hrvanju, 7) Chris Campbel – svjetski šampion u rvanju, 8) Martina Navratilova – svjetska šampionka u tenisu, i još mnogi drugi.</p>
                        <p>Da vegetarijanska ishrana ne uskraćuje mentalnu snagu i inteligenciju, dokazali su mnogi slavni ljudi kroz povijest: 1) Platon, 2) Sveti Ivan Chrysostom (kršćanski mistik), 3) Klement Alexandrijski (ranokršćanski crkveni otac), 4) Ovidije, 5) Sokrat, 6) Pitagora, 7) Leonardo Da Vinci, 8) Isaac Newton, 9) William Shakespeare, 10) Thomas Edison, 11) Albert Einstein, 12) Ralph Waldo Emerson, 13) Lav Tolstoj, 14) Martin Luther, 15) Nikola Tesla, 16) Franz Kafka, 17) Mahatma Gandhi, 18) George Bernard Shaw, 19) Anthony Robbins i mnogi drugi.</p>
                        <p>“Sve dok su naša tijela živi grobovi za ubijene životinje, kako možemo očekivati idealne uvjete na Zemlji?” (Sir G.B. Shaw)</p>
                        <p>U prethodno nabrojenim primjerima koristio sam imena koja su poznata širom svijeta, najširem krugu ljudi. Međutim, upravo vaišnave su najbolji primjer efikasnosti vegetarijanske ishrane u ljudskom životu. Vedska povijest ukazuje na činjenicu da se meso u ljudskom društvu počelo jesti prije otprilike 5000 godina, s početkom doba u kojem trenutno živimo (Kali–yuga; doba degradacije). U to vrijeme je jedenje mesa bilo izuzetno rijetko u odnosu na sveukupni broj stanovništva. Oni koji su jeli meso bili su smatrani otpadnicima društva, niskoklasni i nedodirljivi. Kako se stoljećima smanjivala striktnost slijeđenja vedskih načela – jedenje mesa je sve više uzimalo maha, što je za striktne vaišnave bio siguran znak da necivilizirani ljudi počinju mijenjati standarde i kriterije čovječanstva. Međutim, čak i tada mesojedima nije padalo na pamet da ubijaju krave i jedu njihovo meso. Tada se jelo uglavnom kozje, svinjsko, ptičje i riblje meso.</p>
                        <p>Iskreni vaišnave nikada nisu postali žrtve degradacije, te su tako kroz pet tisućljeća degradacije ljudskog društva očuvali izvornost vedskih i vaišnavskih načela. Vaišnavska povijest puna je imena visokonaprednih vaišnavskih mislilaca, pisaca, filozofa, umjetnika, itd. koji nikada u životu nisu jeli meso – a dali su neprocjenjive priloge čovječanstvu na poljima duhovne znanosti, medicine, filozofije, astronomije, arhitekture, umjetnosti, itd.</p>
                        <br/>
                        <h4>Etika (samilost)</h4>
                        <hr/>
                        <ul>
                            <li>U svijetu svake godine bude ubijeno otprilike 24 milijarde životinja radi potrebe mesne industrije.</li>
                            <li>Samo u SAD svakog sata biva ubijeno oko 660.000 životinja.</li>
                        </ul>
                        <p>Vegetarijanstvo je veliki prilog humanijem svijetu jer životinje koje se uzgajaju radi mesa – žive u nezamislivo okrutnim uvjetima. Lišene su osnovnih životnih potreba – svježeg zraka, prirodne hrane, prostora za kretanje, itd. Evo nekoliko primjera okrutnosti koje se nameću životinjama na farmama mesne industrije.</p>
                        <ul>
                            <li>Pilićima se sijeku kljunovi kako ne bi kljucali jedni druge zbog stresnih uvjeta u kojima žive. Odrasla perad je nabijena jedna do druge što znači da nemaju prostora čak niti da rašire krila. Pod gajbe je napravljen od žice koja im izranjava i deformira noge. Znanstvenici jednostavno ne bi mogli izmisliti bolje inkubatore za viruse kao što su salmonela, E. coli, ptičja groznica i MRSA bakterije.</li>
                            <li>Pošto muški pilići nemaju komercijalnu vrijednost za mesnu industriju jer ne nesu jaja – oni se živi melju u velikim mašinama i od njih se pravi ‘gnojivo’ za oranice ili krmno brašno od kojeg krave poslije dobiju kravlje ludilo.</li>
                            <li>Krave i bikovi sve do klanja budu svezani u uski prostor gdje se ne mogu čak ni okrenuti oko sebe.</li>
                            <li>Kastriranje mužjaka (bikova, svinja, itd.) se vrši bez anestezije.</li>
                            <li>U zimskom razdoblju mnoge životinje uginu od smrzavanja zbog nedostatka grijanja.</li>
                            <li>Za vrijeme transporta na velike udaljenosti, životinje čak po nekoliko dana ne budu hranjene i pojene, te su izvrgnute snažnom vjetru koji stalno puše kroz rešetke kamiona.</li>
                        </ul>
                        <p><b>“Čovjek je zaista kralj svih zvijeri … jer njegova brutalnost je nadišla životinjsku.”</b> (Leonardo Da Vinci)</p>
                        <p>Prije nego što pređem na slijedeći naslov, želim izjaviti kako sam tijekom sakupljanja statističkih podataka za prethodna četiri naslova – u raznim knjigama i na raznim web stranicama nailazio na podatke koji su se donekle razlikovali. Bez obzira da li jedno dijete u svijetu umre od gladi svakih 2.3 sekunde ili svakih 5 sekundi; da li za svaki hamburger biva uništeno 4m2 tropske šume ili 5m2, itd. – jedna stvar je sigurna: Rezultati znanstvenih istraživanja, statistički podaci i svakodnevno iskustvo – ukazuju na činjenicu da je vegetarijanska ishrana jedina Bogom dana ishrana za sva civilizirana ljudska bića koja su zainteresirana za: 1) Očuvanje ovog divnog planeta Zemlje, 2) prosperitet i zdravlje svih ljudi u svijetu, 3) mir i harmoniju u ljudskom društvu, 4) samilost prema životinjama i konačno 5) vlastito zdravlje.</p>
                        <p>Šokantna je činjenica da je u 21. stoljeću, usprkos opsežnom znanstvenom istraživanju, jedenje leševa prihvaćeno kao nešto civilizirano i prirodno. Koliko još ljudi i životinja treba umrijeti u agoniji prije nego li čovječanstvo shvati zakone prirode (konačno Božje zakone)? Njemački filozof Arthur Schopenhauer je rekao: “Sve istine prolaze kroz tri faze. Prvo su ismijavane, zatim su agresivno napadnute i konačno su prihvaćene kao očigledne.” Kroz te tri faze je prolazila izjava da je zemlja okrugla, a kroz iste prolazi i činjenica da je vegetarijanska ishrana jedina pogodna ishrana za (civilizirana) ljudska bića. Ukoliko ste zabrinuti za ekološko zagađenje, bolesti i glad u svijetu, onda malo razmislite kako vaše loše navike podupiru profitere i mesnu industriju koja nemilice pretvara ovaj planet u jednu ogromnu kantu za smeće (u kojoj uvjeti života postaju sve gori i gori).</p>
                        <br/>
                        <h4>Duhovno–filozofski razlozi</h4>
                        <small>(Hranjenje kao izričaj životnih vrijednosti)</small>
                        <hr/>
                        <p>Hranjenje je uz razmnožavanje, branjenje i spavanje jedno od temeljnih načela života, a naš pristup toj po život važnoj aktivnosti određuje nas kao osobe.</p>
                        <p>Prema starim vedskim tekstovima izreka: „Jedem, dakle postojim!“ opisuje najnižu razinu svjesnosti čovjeka, koja gotovo da se i ne razlikuje od one životinjske. Prema istim tekstovima, proces hranjenja jedan je od ključnih čimbenika za dostizanje najviše razine svjesnosti, koja obuhvaća duhovno poimanje stvarnosti i proširuje našu percepciju do neslućenih razmjera. U tom slučaju govorimo o umjetnosti i mudrosti hranjenja.</p>
                        <p>Pristupimo li tome na taj način, zasigurno nadilazimo iskustvo punjenja želučane šupljine, pružanja radosti i zadovoljstva nepcima božanskim okusima, kao i opčinjenosti nosnica omamljujućim mirisima različitih delicija. Ovakva iskustva, koja podređuju osjetila, zasigurno su važna, međutim pročišćeno postojanje, za čije dostizanje ključnu ulogu ima upravo i proces hranjenja, omogućuju ljudskoj vrsti puno složenije i smislenije iskustvo trajne radosti i ispunjenja.</p>
                        <p>Hranjenje je u svom etimološkom, a i funkcionalnom kontekstu potpuno pozitivan proces koji tjelesne stanice snabdijeva kvalitetnom energijom nužnom za normalno funkcioniranje, razvoj i dugovječnost. Unatoč ovim činjenicama, svjedoci smo da proces hranjenja više nego ikada postaje jedan od najopasnijih neprijatelja čovječanstva, ne samo na fizičkoj razini, već i na suptilnim razinama koje kreiraju naša mentalna i duševna stanja. Razlog tomu nije samo loša kvaliteta namirnica, već jednako tako i naš pristup hranjenju. Vrlo često <b>nabacamo</b> u sebe hranu koja nema <b>nutricionističku vrijednost</b>, budući da nekamo <b>žurimo</b>, i zbog toga se <b>prejedamo</b> jer mozak ne stigne komunicirati s probavom. Što tek reći o izgledu hrane koju jedemo, te o najvažnijem aspektu, njenom izboru i načinu pripreme, odnosno stanju svijesti prilikom kojeg pripremamo hranu.</p>
                        <p>Dio vedske mudrosti koji se bavi fizičkim i mentalnim zdravljem naziva se Ayurveda i ta drevna znanost, preteča suvremene medicine, bavi se prije svega prevencijom bolesti. Ayurveda opisuje tri glavna uzroka bolesti: loše higijenske navike, nezadovoljstvo, te, kao glavni uzročnik jer može utjecati na prethodna dva, loša i pogrešna prehrana.</p>
                        <p>U Vedama saznajemo da je vegetarijanska hrana najprikladnija ljudskoj vrsti. U zadnjim desetljećima znanost na svakom koraku potvrđuje da ljudsko tijelo zasigurno postaje izloženo velikom riziku ukoliko uzima nevegetarijansku hranu, a čitav niz smrtonosnih bolesti izravna su posljedica mesne prehrane čovjeka.</p>
                        <p>Isto tako, više nije tabu da je usiljena proizvodnja mesa najveći zagađivač planeta Zemlje te uzrok uništenja tisuća i tisuća hektara prašuma (Zemljinih pluća). Na kraju dolazimo do glavne svrhe prehrane zbog koje je i nastao ovaj osvrt, a to je hrana kao medij uzdizanja svjesnosti, tj. hranjenje kao proces kojim činimo pomak u razumijevanju istinskih vrijednosti u životu, tako potreban suvremenom društvu.</p>
                        <p>Ono što proces hranjenja čini umjetnošću i mudrošću jest potpuna osviještenost o tome kako hrana dolazi na naš tanjur. Osviještenost o misteriji razvoja biljke iz sjemena, o savršenoj interakciji prirodnih konstelacija (savršene kozmičke usklađenosti) u obliku sunčeve topline i svijetlosti, mjesečeva srebrnog sjaja, poput bisera vrijednih kapi kiše bez kojih biljke ne mogu rasti, sazrijevati i dati plodove, o bogatstvima tla koje je poput kraljevske riznice koju kralj otvara u razdobljima nestašica, o trudu i strpljenju brižljiva ratara.</p>
                        <p>Ta osviještenost daje nam osjećaj zahvalnosti i Ljubavi prema Najdivnijem (kreatoru) i Njegovoj kreaciji koja na tako savršen način od pamtivijeka osigurava našu egzistenciju bez nužnog krvoprolića, okrutnog umjetnog umnožavanja i ubijanja nedužnih životinja. Sam čin pripreme namirnica s ovim razumijevanjem prestaje biti dosadna rutina, već je svaki put neponovljivo iskustvo radosti i nevjerojatne kreativnosti koje stvara praznik za sva osjetila i svojim bojama i oblicima i okusima.</p>
                        <p>Kada je vegetarijanska hrana pripremljena i skuhana na taj način, kao izričaj i ponuda zahvalnosti i Ljubavi, te blagovana uz molitvu da ne postanemo žrtva zaborava, njena vrijednost ne samo da dostiže najvišu razinu hranjivosti, već nas isto tako oplemenjuje, produhovljuje i omogućuje potpuno iskustvo zadovoljstva. Prema drevnim Vedama, takva hrana naziva se prasadam (Božja milost) i vrlo opipljivo utječe na našu svjesnost, budeći u nama osjećaje suosjećanja, brižnosti, senzitivnosti, smirenosti i neagresivnosti, dobronamjernosti i harmonije s prirodom i svim živim bićima.<br/>(Tomislav Klasnić - Sundarananda Das)</p>
                    </div>
                 
                    </div>
                    <div className="col-md-12 col-lg-4 sidebar">
               
                    <div className="sidebar-box">
                        <div className="bio text-center ">
                        <img src="./images/authors/sundarananda.jpg" alt="Image Placeholder" className="img-fluid mb-3" />
                          
                            
                            <div className="bio-body">
                                <h6><i>autor članka:</i></h6>
                                <h6><b>Tomislav Klasnić (Sundarananda das)</b></h6>
                                <h6></h6>
                                <p>Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. <Link to="" onClick={handleExpandClick}>više...</Link></p>
                                
                                <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <p>Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBooku</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTubeu</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p>
                                </Collapse>
                             
                            </div>
                        </div>
                    </div>
                  
                    </div>
                </div>
            </div>
        </section>
       
        </>
    );
}

export default VegetarianismPage;
