import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { useState } from "react";

const headerImage: React.CSSProperties = {
    height: '40vh',
    backgroundImage: "url('./images/srila_prabhupada.jpg')",
};

const SrilaPrabhupadaPage: React.FC = () => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <div className="site-cover site-cover-sm same-height overlay single-page" style={headerImage}>
            </div>
            <section className="section">
                <div className="container">
                    <div className="row blog-entries element-animate">
                        <div className="col-md-12 col-lg-8 main-content">
                            <div className="post-content-body">
                                <br />
                                <h1>A.C. Bhaktivedanta Swami Prabhupada</h1>
                                <br />
                                <p>Šrila A.C. Bhaktivedanta Swami Prabhupada (među svojim sljedbenicima poznat kao Šrila Prabhupada) rodio se 1896. godine u Kalkati, Indija. Prvi puta je sreo svog duhovnog učitelja, Šrila Bhaktisiddhanta Sarasvati Thakura u Kalkati 1922. godine još dok je radio kao mladi kemičar. U to vrijeme je Šrila Prabhupadovo ime bilo Abhay Charanaravinda De.</p>
                                <div className="row my-4">
                                    <div className="col-md-12 mb-4">
                                        <img src="./images/sp/sp1.jpg" alt="Image placeholder" className="img-fluid rounded" />
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <img src="./images/sp/sp2.jpg" alt="Image placeholder" className="img-fluid rounded" />
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <img src="./images/sp/sp3.jpg" alt="Image placeholder" className="img-fluid rounded" />
                                    </div>
                                </div>
                                <p>Već prilikom njihovog prvog susreta, Šrila Bhaktisiddhanta Sarasvati Thakura je zamolio Šrila Prabhupada da pomoću engleskog jezika raširi vedsko znanje po čitavom svijetu. U godinama koje su uslijedile, Šrila Prabhupada je napisao komentare na Bhagavad–gitu, pomagao drugim vaišnavama u njihovim misionarskim aktivnostima i 1944. godine bez ičije pomoći započeo pisanje i tiskanje Back to Godhead, duhovnog magazina na engleskom jeziku, koji je izlazio dva puta mjesečno. (Taj magazin na engleskom jeziku izlazi još uvijek. Ako ste zainteresirani, možete više o tome saznati na web stranici http://www.krishna.com) Priz.najući njegovu filozofsku učenost i devociju, Gaudiya Vaišnava društvo mu je 1947. godine dodijelilo titulu Bhaktivedanta.</p>
                                <p>Kada je imao 54 godine (1950. godine), Šrila Prabhupada se povukao iz porodičnog života. Otputovao je u sveti grad Vrindavanu gdje je živio u vrlo skromnim okolnostima u povijesnom hramu Radha–Damodara. Ondje je proveo nekoliko godina u dubokom studiranju i pisanju. 1959. godine prihvatio je odvojeni red života (sannyasu). U to vrijeme je započeo rad na svom životnom remek dijelu; opsežnom prijevodu i komentarima na Šrimad–Bhagavatam (duhovni ep od 18.000 stihova na sanskrtu, zapisanom prije više od 5000 godina).</p>
                                
                                <p>Kada je publicirao prva tri sveska Šrimad–Bhagavatama, Šrila Prabhupada je došao u SAD 1965. godine da bi ispunio misiju svog duhovnog učitelja. Nakon toga je napisao više od 50 svezaka autoriziranih komentara, prijevoda i zbirki studija filozofskih i religijskih klasika vedske literature.</p>
                                <p>Kada je 1965. godine stigao parobrodom u Ameriku, Šrila Prabhupada je bio gotovo bez ikakvog novca. Trebalo mu je skoro godinu dana teškog truda da osnuje ISKCON (Međunarodno društvo za svjesnost Krišne). Prije nego što je umro (14. listopada 1977. godine), vodio je svoje duhovno društvo i uspostavio više od stotinu ašrama (hramova), škola, instituta i farmi širom svijeta. Usprkos svojoj poznoj dobi, Šrila Prabhupada je u dvanaest godina proputovao čitavim svijetom četrnaest puta, obavljajući svoje misionarske dužnosti. I pored mnoštva intenzivnih i zahtjevnih dužnosti, nastavio je sa svojim spisateljskim radom. Njegovi prijevodi i komentari sačinjavaju čitavu knjižnicu vedske filozofije, religije, znanosti i kulture. Šrila Prabhupadove knjige su vrlo visoko cijenjene u akademskim krugovima po svojoj vjerodostojnosti, dubini i jasnoći. Do sada su prevedene na preko 60 jezika i vrlo su popularne u mnogim sveučilištima, školama i knjižnicama širom svijeta.</p>
                                <p>Tijekom mnogih godina, na stotine znanstvenika (koji su ili osobno sreli Šrila Prabhupada ili čitali njegove knjige), izrazili su svoje iskreno priznanje njegovih osobnih odlika i priloga koje je dao čovječanstvu kroz svoja učenja. Na primjer, Dr. Harvey Cox, svjetski poznati profesor religije sa harvardskog sveučilišta, opisuje kako je postupno prepoznao neprocjenjivu vrijednost Šrila Prabhupadovih priloga.</p>
                                <p>“Kada sam prvi puta sreo članove Hare Krišna pokreta, bio sam izrazito iznenađen i pitao sam se o čemu se radi. Njihova odjeća, pjevanje mantri i obrijane glave, učinili su mi se ‘pomalo’ čudnim. Ali kako sam postao sve više upoznat sa pokretom, počeo sam otkrivati velike sličnosti u suštini onoga što su oni poučavali i izvornih učenja kršćanstva, a to je – jednostavno življenje bez truda za nagomilavanjem materijalnih dobara, osjećanje samilosti prema svim živim bićima i izmjena ljubavi. Ja sam zaista impresioniran činjenicom kako su učenja jednog čovjeka i duhovna tradicija koju je donio sa sobom, napravili duboku pozitivnu promjenu u životima tako velikog broja ljudi. Po mom mišljenju, Šrila Prabhupadovi prilozi su od izuzetne važnosti i ostaviti će svoj utjecaj još mnogo godina.”</p>
                                <p>Šrila Prabhupada je imao 69 godina kada je krenuo na Zapad. U tom dobu se ljudi obično sklupčaju kraj nekog prozora, pomalo gledaju televiziju, čitaju novine i čekaju smrt. Šrila Prabhupada je živio vrlo skromno, ali udobno u Vrindavani i nije imao nikakvog osobnog sebičnog interesa zašto bi napustio to sveto mjesto koje je toliko volio, da bi konačno došao u urbanu džunglu degradiranog američkog društva. Materijalno govoreći, sa sobom je imao vrlo malo toga; tek osobna prtljaga i 40 Rupija (u to vrijeme vrijednost od $8). Ali sa duhovne točke gledišta – imao je neprocjenjivo bogatstvo; lijek i rješenja za sve ludosti i patnje ovoga svijeta - maha–mantru. Počeli su dolaziti prvi sljedbenici koji su mu pomogli otvoriti i prvi Krišnin hram na Zapadu.</p>
                                <p>Tri puta tjedno je davao lekcije iz Bhagavad–gite; kuhao za goste i služio ih. Tijekom dvanaest godina svojih intenzivnih misionarskih aktivnosti, uz sve dnevne dužnosti redovito je radio na prijevodu i komentarima vaišnavskih tekstova. Svoje osobne potrebe sveo je na minimum, pa je zato spavao samo oko 4 sata dnevno, kako bi mogao iskoristiti što više vremena za širenje Krišninih poruka čovječanstvu.</p>
                                <p>Šrila Prabhupadov čitav život bio je praktičan primjer kako se ponaša i kako razmišlja savršeni duhovni džentlmen. Bio je svetac, vizionar, pisac, filozof i oličenje svih najuzvišenijih osobina koje netko može posjedovati. Njegov odlazak iz ovog svijeta i povratak u duhovni, također je bio dramatičan i poučan. U zadnjih nekoliko mjeseci svoga života, Šrila Prabhupada više nije mogao pravilno probavljati hranu, i zbog toga je izuzetno malo jeo. Bio je toliko slab i mršav da je mogao samo nepomično ležati u krevetu. Pošto mu je glas bio gotovo nečujan, njegovi učenici koji su ga služili, držali su do njegovih usta mikrofon kako bi ga mogli čuti što govori. A što je govorio? Koristio je zadnje atome svoje energije prevodeći Šrimad–Bhagavatam za dobrobit svih budućih generacija uvjetovanih duša.</p>
                                <p>Šrila Prabhupada je bio Krišnin izaslanik iz duhovnog svijeta; specijalno opunomoćen da iskorjenjuje ateizam, lažnu religioznost i sve ostalo što je povezano sa time. Na žalost, iako je njegov duhovni doprinos bio izuzetno bogat, mnogi ljudi u svijetu još uvijek nisu svjesni važnosti njegovih duhovnih priloga. Takav je bio čovjek koji je svojim učenjima pomogao, i još uvijek pomaže tisućama ljudi širom svijeta da ostave ne samo drogu, alkohol i druge grube grješne aktivnosti već i sebični materijalistički način življenja, te da postanu duhovni i moralni ljudi puni vrlinskih odlika. Kontakti na kraju knjige mogu vam pomoći da si nabavite biografiju Šrila Prabhupada.</p>
                                <p>Evo popisa nekih Šrila Prabhupadovih neponovljivih dostignuća. Ovaj popis koristim uz odobrenje autora Sri Nandanandana dasa (Stephen Knapp). Preuzeto sa njegove <a href="http://www.stephen-knapp.com">Internet stranice</a></p>
                                <ul>
                                    <li>1965. godine, Šrila Prabhupada je po prvi puta otputovao izvan Indije, da bi ispunio želju svog duhovnog učitelja. Na svom putovanju parobrodom, doživio je dva srčana udara.</li>
                                    <li>Već nakon godinu dana mukotrpnog propovijedanja u New Yorku, uz pomoć grupice svojih učenika – osnovao je ISKCON (International society for Krishna consciousness / Međunarodno društvo za svjesnost Krišne). To je bilo po prvi puta u povijesti da je Krišnin bhakta uspješno trenirao ne–Indijce u striktnoj disciplini vaišnavizma.</li>
                                    <li>Službeno je inicirao otprilike pet tisuća učenika, koji su predstavljali veliku raznolikost u nacionalnostima, rasama, etničkim korijenima, religioznim tradicijama, društvenim statusima i obrazovanju.</li>
                                    <li>Osnovao je 108 Krišninih hramova na šest kontinenata, uspostavio obožavanje Božanstava u svakom hramu i istrenirao svoje učenike o procesu obožavanja.</li>
                                    <li>Započeo je tzv. “Sunday Feast” (nedjeljne gozbe), besplatno dijeljenje prasadama (produhovljene vegetarijanske hrane) milijunima ljudi u svim svjetskim ISKCON centrima.</li>
                                    <li>Osnovao je “Food for Life” (Hrana za život) program koji se sastoji od besplatne distribucije prasadama širom svijeta, siromašnim ljudima, beskućnicima, sirotištima, studentima, bolnicama u ratom pogođenim zemljama, itd.</li>
                                    <li>Stvorio je prvi lanac vegetarijanskih restorana u svijetu.</li>
                                    <li>Svakodnevno je davao lekcije na temu filozofije svjesnosti Krišne. Preko 2200 tih lekcija je snimljeno i pohranjeno u arhivu. Danas su na raspolaganju u obliku DVD, VCD i MP3 formata. Svi ti video i audio zapisi (na engleskom jeziku) mogu se naručiti preko web stranica <a href="http://www.krishna.com">krishna.com</a> i <a href="http://www.blservices.com">blservices.com</a></li>
                                    <li>Vodio je na stotine razgovora o znanosti i filozofiji svjesnosti Krišne sa svojim učenicima, prijateljima i gostima (znanstvenicima, svećenicima, novinarima, itd.) Preko 1300 tih razgovora je snimljeno i pohranjeno u arhivu.</li>
                                    <li>Snimio je dvadeset i četiri albuma drevne duhovne glazbe.</li>
                                    <li>Izdavao je mjesečni magazin Back to Godhead (kojega je započeo sam još 1944. godine). Na vrhuncu svoje cirkulacije, sredinom ‘70–tih godina, svaki broj magazina je bio prodavan u milijunima primjeraka diljem svijeta.</li>
                                    <li>Dao je sagraditi velike hramove u Bombayu i Vrindavani, te je osnovao duhovni grad u Mayapuru. Svi su postali mjesta hodočašća od međunarodnog značaja. Sve do današnjeg dana milijuni ljudi iz čitavog svijeta dolazi posjetiti ta mjesta hodočašća.</li>
                                    <li>Osnovao je “Bhaktivedanta Institute” sa ciljem promidžbe znanosti o Krišni u znanstvenim i filozofskim krugovima. Na taj način je uputio mnoge svjetski poznate akademike u osnove znanosti o samospoznaji</li>
                                    <li>Osnovao je "Bhaktivedanta Swami Charity Trust" sa ciljem očuvanja i renovacije svetih mjesta vezanih uz pojavu i aktivnosti Šri Caitanye Mahaprabhua.</li>
                                    <li>Osnovao je komune na farmama, poučavajući načelo “jednostavan život, visoko razmišljanje”, naglašavajući važnost zaštite krava i život u harmoniji sa Bogom i prirodom.</li>
                                    <li>Trenirao je svoje učenike o kompleksnim temama menadžmenta, filozofije i privatnih problema – u više od 6000 pisama od kojih je većina sačuvana u arhivu (i koja su tiskana u obliku šest knjiga).</li>
                                    <li>Usprkos svojoj poodmakloj dobi, spavao je samo oko četiri sata dnevno, kako bi imao što više vremena posvetiti aktivnostima širenja duhovne znanosti o Krišni (onako kako je već prorečeno prije više od 5000 godina). Obično bi se ustao oko 2 sata ujutro te nakon kupanja i meditacije započeo bi sa prevođenjem i pisanjem knjiga. Napisao je preko 50 knjiga na temu duhovne znanosti o Krišni, koje su do danas prevedene na više od 60 svjetskih jezika.</li>
                                    <li>Mnogi uzoriti akademici i profesori sa vodećih svjetskih sveučilišta pohvalili su Šrila Prabhupadov literarni doprinos. “Encyclopaedia Britannica” je izjavila da su njegovi obimni prijevodi sa izvornog sanskrta i njegovi komentari “zapanjili literarne i akademske krugove u svijetu.” Taj podvig je još značajniji zbog činjenice da su prijevodi i komentari bili na engleskom jeziku koji nije bio njegov materinji jezik.</li>
                                    <li>1972. godine osnovao je “Bhaktivedanta Book Trust” (BBT) sa ciljem masovne proizvodnje svojih knjiga. Do 1976. godine, 55.000.00 knjiga je bilo tiskano na 25 jezika. Za jedno tiskanje Bhagavad–gite, bilo je potrebno 76 željezničkih vagona da prevezu potrebnu količinu papira.</li>
                                    <li>Od 1965. do 1977. godine obišao je svijet četrnaest puta aktivno djelujući u misiji širenja vaišnavske/vedske duhovne filozofije i tradicije.</li>
                                    <li>Vješto je upravljao ISKCON–om jednostavno svojim osobnim posjetama i pismima, gotovo bez korištenja telefona.</li>
                                </ul>
                                <br/>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 sidebar">
                            <div className="sidebar-box">
                                <div className="bio text-center ">
                                    <img src="./images/authors/sundarananda.jpg" alt="Image Placeholder" className="img-fluid mb-3" />
                                    <div className="bio-body">
                                        <h6><i>autor članka:</i></h6>
                                        <h6><b>Tomislav Klasnić (Sundarananda das)</b></h6>
                                        <h6></h6>
                                        <p>Rođen je u Lipiku 1970 godine. Odrasta uz Novi Val i krajem svojeg srednjoškolskog obrazovanja postavlja si pitanja o smislu postojanja. Kroz vegetarijanstvo i yogu započinje svoju potraga za istinom. <Link to="#" onClick={handleExpandClick}>više...</Link></p>
                                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                                            <p>Na studiju geodezije rasplamsava se njegova ljubav prema Vedskom znanju i prakticiranju Bhakti yoge, koju nastavlja prakticirati i danas. Odluka o posvećenom proučavanju duhovne poruke Bhakti yoge dovodi ga u ašram, školu cjeloživotnog duhovnog obrazovanja. 1990 godine susreće se sa svojim Guruom, te kasnije dobiva incijaciju u Bhakti tradiciji Vedske škole duhovnog znanja. U Indiju, koljevku zanja o yogi, otputovao je već više od 15-ak puta. Deset godina je služio kao misionar a 15 godina kao voditelj ašrama. Osnivač je i predsjednik udruge Centar za Vedske studije. Organizator je i suorganizator brojnih duhovnih retrita, mantra meditacija, Kirtan festivala (koncerti duhovne glazbe) u Lisinskom, HNK-u i Bogaloo-u. Osnivač je projekata Bhakti Yoga Centar na <Link to="https://www.facebook.com/BYCZagreb/">FaceBooku</Link> te na <Link to="https://www.youtube.com/channel/UCZwJF4Ip3nS7dn25G2WvI7A">YouTubeu</Link>. Osnivač je i voditelj projekta <Link to="https://www.facebook.com/SoulFoodHr">Soul food</Link> (hrana za dušu, klupski zdravljak). Proučavatelj je i predavač Vedske filozofije u Bhakti yoga tradiciji duhovnog znanja Veda. Vrtlar organskog povrća i voća. Doživotni učenik kulture nesebičnosti.</p>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SrilaPrabhupadaPage;
