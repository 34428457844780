import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';

interface Post {
    id: number;
    title: string;
    created_date: string;
    html: string;
}

const Posts: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);

    useEffect(() => {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        };

        fetch(`${config.backendUrl}/api/posts`, requestOptions)
            .then((response) => response.json())
            .then((data: Post[]) => {
                setPosts(data);
            })
            .catch(err => {
                console.log(err);
            });

    }, []);

    function renderPost(post: Post) {
        return { __html: post.html };
    }

    return (
        <>
            <div className="row g-3">
                <div className="col-md-12">
                    <div className="row g-3">
                        {posts.length > 0 ? (
                            posts.map((p) => (
                                <div key={p.id} className="col-md-3">
                                    <div className="blog-entry">
                                        <a href={`/posts/${p.id}`} className="img-link">
                                            <img src="./images/typewritter.jpg" alt="Image" className="img-fluid" />
                                        </a>
                                        <span className="date">{p.created_date}</span>
                                        <h5>{p.title}</h5>
                                        <p><Link to={`/posts/${p.id}`} className="btn btn-sm btn-outline-primary">otvori</Link></p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <span>No posts found</span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Posts;
