import { useContext } from "react";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { appContext } from '../App';

interface CustomAlertProps {
    message: string;
    title: string;
    showAlert: boolean;
    alertType: string;
}

const CustomAlert: React.FC<CustomAlertProps> = (props) => {
    const context = useContext(appContext);
    if (!context) {
        return null; // or handle the error appropriately
    }
    const { alertType, showAlert, toggleAlert } = context;

    const closeAlert = () => {
        toggleAlert(false);
    };

    return (
        <>
            <Alert show={showAlert} variant={alertType}>
                <Alert.Heading>{props.title}</Alert.Heading>
                <p dangerouslySetInnerHTML={{ __html: props.message }} />
                <div className="d-flex justify-content-end">
                    <Button onClick={closeAlert} variant={`outline-${alertType}`}>
                        zatvori
                    </Button>
                </div>
            </Alert>
        </>
    );
}

export default CustomAlert;
