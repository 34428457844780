import { useEffect, useState, useContext, ChangeEvent, FormEvent } from "react";
import { useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import { appContext, alertTypes } from '../App';
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import config from '../config';

const headerImage = {
    backgroundColor: '#214252'
};

interface MessagePayload {
    name: string;
    email: string;
    title: string;
    message: string;
}

const ContactPage: React.FC = () => {
    const context = useContext(appContext);
    if (!context) {
        throw new Error("appContext must be used within a AppProvider");
    }
    const { toggleAlert, changeAlertType } = context;
    const { jwtToken, setAlertTitle, setAlertMessage } = useOutletContext<{ jwtToken: string, setAlertTitle: (title: string) => void, setAlertMessage: (message: string) => void }>();
    const [disable, setDisable] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [messagePayload, setMessage] = useState<MessagePayload>({
        name: "",
        email: "",
        title: "",
        message: "",
    });

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setDisable(true);

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(messagePayload),
        };

        fetch(`${config.backendUrl}/api/send-message`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setTimeout(() => {
                    if (data.error) {
                        changeAlertType(alertTypes.Danger);
                        setAlertTitle("Upozorenje");
                        setAlertMessage(data.message);
                    } else {
                        changeAlertType(alertTypes.Success);
                        setAlertTitle("Vaša poruka je uspješno poslana.");
                        setAlertMessage("");
                        setMessage({
                            name: "",
                            email: "",
                            title: "",
                            message: "",
                        });
                    }

                    toggleAlert(true);
                    setDisable(false);
                }, 5000);
            })
            .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err.message);
                console.log(err);
            });
    };

    const hasError = (key: string) => {
        return errors.indexOf(key) !== -1;
    };

    const handleChange = (name: keyof MessagePayload) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;

        setMessage({
            ...messagePayload,
            [name]: value
        });
    };

    return (
        <>
            <div className="hero overlay inner-page" style={headerImage}>
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center pt-5">
                        <div className="col-lg-6">
                            <h1 className="heading text-white mb-3" data-aos="fade-up">Kontaktirajte nas</h1>
                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section">
                <div className="container">
                    <br />
                    <div className="row">
                        <div className="col-lg-4 mb-5 mb-lg-0" data-aos="fade-up" data-aos-delay="100">
                            <div className="contact-info">
                                <div className="address mt-2">
                                    <i className="icon-room"></i>
                                    <h4 className="mb-2">Lokacija:</h4>
                                    <p>Ulica Milana Amruša 8/III,<br /> 10000 Zagreb</p>
                                </div>

                                <div className="email mt-4">
                                    <i className="icon-envelope"></i>
                                    <h4 className="mb-2">Email:</h4>
                                    <p>info.cvszg@gmail.com</p>
                                </div>

                                <div className="phone mt-4">
                                    <i className="icon-phone"></i>
                                    <h4 className="mb-2">Mobilni telefon:</h4>
                                    <p>+385 99 324 95 03</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-6 mb-3">
                                        <Input
                                            title={""}
                                            placeholder="Vaše ime"
                                            className={"form-control"}
                                            type={"text"}
                                            name={"name"}
                                            value={messagePayload.name}
                                            onChange={handleChange("name")}
                                            errorDiv={hasError("name") ? "text-danger" : "d-none"}
                                            errorMsg={"Unesite vaše ime. Ovo polje nesmije biti prazno."}
                                        />
                                    </div>
                                    <div className="col-6 mb-3">
                                        <Input
                                            title={""}
                                            placeholder="Email"
                                            className={"form-control"}
                                            type={"email"}
                                            name={"email"}
                                            value={messagePayload.email}
                                            onChange={handleChange("email")}
                                            errorDiv={hasError("email") ? "text-danger" : "d-none"}
                                            errorMsg={"Unesite vaš email. Ovo polje nesmije biti prazno."}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <Input
                                            title={""}
                                            placeholder="Naslov poruke"
                                            className={"form-control"}
                                            type={"text"}
                                            name={"title"}
                                            value={messagePayload.title}
                                            onChange={handleChange("title")}
                                            errorDiv={hasError("title") ? "text-danger" : "d-none"}
                                            errorMsg={""}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <TextArea
                                            title=""
                                            placeholder="Poruka"
                                            name={"message"}
                                            rows={7}
                                            value={messagePayload.message}
                                            onChange={handleChange("message")}
                                            errorDiv={hasError("message") ? "text-danger" : "d-none"}
                                            errorMsg={"Unesite vašu poruku. Ovo polje nesmije biti prazno."}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <input value="Pošalji poruku" type="submit" className="btn btn-primary" disabled={disable} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
                </>
    );
}

export default ContactPage;
