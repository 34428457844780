import { useEffect, useState, useContext, FormEvent } from "react";
import Input from './form/Input';
import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { appContext, alertTypes } from '../App';
import config from '../config';

const ResetPasswordPage: React.FC = () => {
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const navigate = useNavigate();
    const location = useLocation();
    
    // Parse the query parameter from the URL
    const email = new URLSearchParams(location.search).get("email");

    
    const appContextValue = useContext(appContext);
    if (!appContextValue) {
        throw new Error("appContext is undefined");
    }
    const { toggleAlert, changeAlertType } = appContextValue;
    const { jwtToken } = useOutletContext<{ jwtToken: string }>();
    const { setAlertTitle, setAlertMessage } = useOutletContext<{
        setAlertTitle: (title: string) => void, 
        setAlertMessage: (message: string) => void 
    }>();

    useEffect(() => {
        // Check if email is missing, and show alert if necessary
        if (!email) {
            changeAlertType(alertTypes.Danger);
            setAlertTitle("Error");
            setAlertMessage("Email parameter is missing.");
            toggleAlert(true);
            navigate('/');
        }
    }, [email, changeAlertType, setAlertTitle, setAlertMessage, toggleAlert, navigate]);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        // Build the request payload
        const payload = {
            password: password,
            confirm_password: confirmPassword,
            email: email, // Include the email from query parameter
        };

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload),
        };

        fetch(`${config.backendUrl}/api/change-password`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Error");
                    setAlertMessage(data.message);
                    toggleAlert(true);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Password reset successful!");
                    setAlertMessage(data.message);
                    toggleAlert(true);
                    // navigate('/');
                }
            })
            .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Error");
                setAlertMessage(err.message || "An error occurred");
                toggleAlert(true);
            });
    };

    return (
        <div className="col-md-6 offset-md-3">
            <br/>
            <h2>Reset Password</h2>
            <hr/>

            <form onSubmit={handleSubmit}>
                <Input 
                    title="Password"
                    type="password"
                    className="form-control"
                    name="password"
                    autoComplete="new-password"
                    onChange={(event) => setPassword(event.target.value)}
                />
                <Input 
                    title="Verify Password"
                    type="password"
                    className="form-control"
                    name="password-confirm"
                    autoComplete="new-password-confirm"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                />
                <hr/>

                <input 
                    type="submit"
                    className="btn btn-primary"
                    value="Reset password"
                />
            </form>
            <br/>
            <br/>
        </div>
    );
};

export default ResetPasswordPage;
