import { useContext } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { appContext } from '../App';

interface ModalAlertProps {
  title: string;
  message: string;
  showModalAlert: boolean;
}

const ModalAlert: React.FC<ModalAlertProps> = (props) => {
  const context = useContext(appContext);
  if (!context) {
    throw new Error("appContext must be used within a AppProvider");
  }
  const { showModalAlert, modalReturnValue } = context;

  const handleClose = () => {
    modalReturnValue(false);
  };

  const closeAlert = () => {
    modalReturnValue(true);
  };

  return (
    <>
      <Modal
        show={showModalAlert}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: props.message }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={closeAlert}>Understood</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAlert;
