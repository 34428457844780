import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/hr';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import config from '../config';

const placeholder = {
    height: '20vh',
    backgroundImage: "url(./images/carousel/placeholder.png)",
    display: 'flex',
    alignItems: 'center', 
}

const circularProgress = {
    display: 'block',
    margin: '0px auto',
}

dayjs.extend(utc);
//dayjs.extend(timezone);
dayjs.locale('hr');
dayjs.extend(advancedFormat)
//dayjs().tz('Europe/Zagreb');

interface Event {
    id: number;
    title: string;
    category: number;
    start_datetime: string;
    end_datetime: string;
}

interface EventCategory {
    id: number;
    value: string;
    img: string;
}

const Events = () => {
    const [isLoading, setLoading] = useState(true);
    const [events, setEvents] = useState<Event[]>([]);
    const navigate = useNavigate();

    const eventCategories: EventCategory[] = [
        {
            id: 1,
            value: "Bhagavad Gita",
            img: './../images/events/bg.jpg'
        },
        {
            id: 2,
            value: "Srimad Bhagavatam",
            img: './../images/events/sb.jpg'
        },
        {
            id: 3,
            value: "Kirtan večer",
            img: './../images/events/kirtan.jpg'
        },
        {
            id: 4,
            value: "Caitanya Caritamrta",
            img: './../images/events/cc.jpg'
        },
        {
            id: 5,
            value: "Posebna prigoda",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 6,
            value: "Festival",
            img: './../images/events/byc-logo.png'
        },
        {
            id: 7,
            value: "Ostalo",
            img: './../images/events/byc-logo.png'
        },
    ];

    function getEventImage(event: Event): string {
        const result = eventCategories.find(({ id }) => id === event.category);
        return result?.img || '';
    }

    function loadEvents() {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
        }

        fetch(`${config.backendUrl}/api/events-from-today`, requestOptions)
            .then((response) => response.json())
            .then((data: Event[]) => {
                setEvents(data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }

    useEffect(() => {
        loadEvents();
    }, []);

    function getEvents(): Event[] {
        return events?.length > 0 ? events : [];
    }

    function getEventDates(event: Event): string {
        const startDate = dayjs.utc(event.start_datetime);
        const endDate = dayjs.utc(event.end_datetime);
        const formattedStartDate = startDate.format('dddd, D. MMM, YYYY HH:mm');
        const formattedEndDate = endDate.format('dddd, D. MMM, YYYY HH:mm');

        if (startDate.isSame(endDate, 'date')) {
            return `${startDate.format('dddd, D. MMM')} (${startDate.format('HH:mm')} - ${endDate.format('HH:mm')})`;
        }

        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    const navigateTo = (event: React.MouseEvent<HTMLAnchorElement>, relativeUrl: string) => {
        event.preventDefault();
        navigate(relativeUrl);
    }

    return (
        <>
            <div className="row g-3">
                <div className="col-md-12">
                    <div className="row g-3 align-self-center">
                    {
                        (() => {
                            if (isLoading) {
                                return (
                                    <div style={placeholder}>
                                        <CircularProgress style={circularProgress} />
                                    </div>
                                )
                            } else {
                                return (
                                    getEvents().map((e) => (
                                        <div key={e.id} className="col-md-4">
                                            <div className="blog-entry">
                                                <a className="img-link" onClick={(event) => navigateTo(event, `/events/${e.id}`)}>
                                                    <img src={getEventImage(e)} className="img-fluid" alt={e.title}></img>
                                                </a>
                                                <span className="date">{getEventDates(e)}</span>
                                                <h5>{e.title}</h5>
                                                <p><Link to={`/events/${e.id}`} className="btn btn-sm btn-outline-primary">Otvori</Link></p>
                                            </div>
                                        </div>
                                    ))
                                )
                            }
                        })()
                    }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Events;
