import React from 'react';
import Events from './Events';

const EventsPage: React.FC = () => {
    return (
        <>
            <section className="section posts-entry">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-sm-6">
                            <h5 className="posts-entry-title">Raspored svih događanja</h5>
                        </div>
                    </div>
                    <Events />
                </div>
            </section>
        </>
    );
}

export default EventsPage;
