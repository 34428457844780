import { useState, useContext, FormEvent } from "react";
import Input from './form/Input';
import { useNavigate, useOutletContext, Link } from "react-router-dom";
import { appContext, alertTypes } from '../App';
import config from '../config';

interface AuthResponse {
    error?: boolean;
    message?: string;
    access_token?: string;
    is_admin?: boolean;
    user_id?: string;
}

const Login: React.FC = () => {
    const appCtx = useContext(appContext);
    if (!appCtx) {
        throw new Error("appContext is undefined");
    }
    const { toggleAlert, changeAlertType, changeUserID } = appCtx;

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const { setJwtToken, setIsAdmin, setAlertTitle, setAlertMessage, toggleRefresh } = useOutletContext<{
        setJwtToken: (token: string) => void;
        setIsAdmin: (isAdmin: boolean) => void;
        setAlertTitle: (title: string) => void;
        setAlertMessage: (message: string) => void;
        toggleRefresh: (refresh: boolean) => void;
    }>();

    const navigate = useNavigate();

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        // build the request payload
        const payload = {
            email: email,
            password: password,
        };

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(payload),
        };

        fetch(`${config.backendUrl}/api/authenticate`, requestOptions)
            .then((response) => response.json())
            .then((data: AuthResponse) => {
                if (data.error) {
                    changeAlertType(alertTypes.Warning);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message || "An error occurred");
                } else {
                    setJwtToken(data.access_token || "");
                    setIsAdmin(data.is_admin || false);
                    changeUserID(Number(data.user_id) || 0);
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Dobrodošli u Centar za Vedske Studije Zagreb");
                    setAlertMessage("");
                    toggleRefresh(true);
                    navigate('/');
                }
                toggleAlert(true);
            })
            .catch(error => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(error.message || "An error occurred");
                toggleAlert(true);
            });
    };

    return (
        <div className="col-md-6 offset-md-3">
            <br/>
            <h2>Ulogiraj se</h2>
            <hr/>

            <form onSubmit={handleSubmit}>
                <Input 
                    title="Email adresa"
                    type="email"
                    className="form-control"
                    name="email"
                    autoComplete="email-new"
                    onChange={(event) => setEmail(event.target.value)}
                />
                <Input 
                    title="Lozinka"
                    type="password"
                    className="form-control"
                    name="password"
                    autoComplete="password-new"
                    onChange={(event) => setPassword(event.target.value)}
                />

                <hr/>

                <input
                    type="submit"
                    className="btn btn-primary"
                    value="Ulogiraj se"
                />

                <br/>
                <Link to="/forgot-password">Zaboravili ste lozinku?</Link>
                <br/>
                <hr/>
                <br/>
                <h6>Još uvijek nemate otvoren račun?</h6>
                <Link to="/registration" className="btn btn-primary">Registriraj novi račun</Link>
            </form>
            <br/>
            <br/>
        </div>
    );
};

export default Login;
