import { useNavigate, Link } from "react-router-dom";

const FailedResetPasswordPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="col-md-6 offset-md-3">
            <br/>
            <h2>Failed to reset your password</h2>
            <hr/>
            <p>
                Unfortunately, it seems that the provided link for password reset is malformed or has expired. Click on the reset password link below to request a new link.
            </p>
            <br/>
            <Link to="/forgot-password">Reset password</Link>
            <br/>
            <br/>
        </div>
    );
};

export default FailedResetPasswordPage;
