import { useEffect, useState, useContext, useCallback, useRef } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/de';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { appContext, alertTypes } from '../App';
import { Box, Button } from "@mui/material";
import type { EditorOptions } from "@tiptap/core";
import config from '../config';

import {
    LinkBubbleMenu,
    RichTextEditor,
    TableBubbleMenu,
    insertImages,
    type RichTextEditorRef,
} from "mui-tiptap";
import EditorMenuControls from "./editor/EditorMenuControls";
import useExtensions from "./editor/useExtensions";
import { EditorView } from "@tiptap/pm/view";

dayjs.extend(utc);
dayjs.extend(timezone);

interface EditorProps {
    content: string;
    saveContent: (content: string) => void;
}

interface EventPayload {
    id: number;
    title: string;
    author: number;
    category: number;
    description: string;
    start_datetime: string;
    end_datetime: string;
    created_at: string;
    updated_at: string;
}

interface ImageToDelete {
    id: number;
    filename: string;
}

const fileListToImageFiles = (fileList: FileList): File[] => {
    return Array.from(fileList).filter((file) => {
        const mimeType = (file.type || "").toLowerCase();
        return mimeType.startsWith("image/");
    });
}

const EditEvent: React.FC = () => {
    const rteRef = useRef<RichTextEditorRef>(null);
    const navigate = useNavigate();

    const context = useContext(appContext);
    if (!context) {
        throw new Error("appContext must be used within an AppProvider");
    }
    const { toggleAlert, changeAlertType } = context;

    const { jwtToken } = useOutletContext<{ jwtToken: string }>();
    const { setAlertTitle, setAlertMessage } = useOutletContext<{ setAlertTitle: (title: string) => void, setAlertMessage: (message: string) => void }>();
    const [errors, setErrors] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState<number | null>(null);

    const eventCategories = [
        { id: 1, value: "Bhagavad Gita" },
        { id: 2, value: "Srimad Bhagavatam" },
        { id: 3, value: "Kirtan večer" },
        { id: 4, value: "Caitanya Caritamrta" },
        { id: 5, value: "Posebna prigoda" },
        { id: 6, value: "Festival" },
        { id: 7, value: "Ostalo" },
    ];

    const hasError = (key: string) => {
        return errors.indexOf(key) !== -1;
    }

    const [eventPayload, setEvent] = useState<EventPayload>({
        id: 0,
        title: "",
        author: 0,
        category: 0,
        description: "",
        start_datetime: "",
        end_datetime: "",
        created_at: "",
        updated_at: "",
    });

    let imageId = 0;
    const [imagesToDelete, setImagesToDelete] = useState<ImageToDelete[]>([]);

    let { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
            credentials: 'include',
        }

        fetch(`${config.backendUrl}/admin/events/${id}`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                setEvent(data.event);
                setSelectedOption(data.event.category);
                rteRef.current?.editor?.commands.setContent(data.event.description);

            })
            .catch(err => {
                console.log(err);
            });
    }, [jwtToken, navigate, id])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (jwtToken === "") {
            navigate("/login");
            return;
        }

        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions = {
            method: "POST",
            headers: headers,
            credentials: 'include' as RequestCredentials,
            body: JSON.stringify(eventPayload, null, "\t"),
        }

        fetch(`${config.backendUrl}/admin/update-event`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Warning");
                    setAlertMessage(data.message);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Event was successfully updated!");
                    setAlertMessage(data.message);
                }

                toggleAlert(true);
            })
            .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err);
                toggleAlert(true);
                console.log(err);
            });
    }

    const deleteImage = async (filename: string) => {
        console.log(`trying to delete: '${filename}'`);

        const spliter = "https://minio.daystorm.institute/cvs-zagreb";
        let target = filename.split(spliter)[1];

        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions: RequestInit = {
            method: "GET",
            headers: headers,
            credentials: 'include' as RequestCredentials,
        };

        const encodedTarget = encodeURIComponent(target);
        return fetch(`${config.backendUrl}/admin/delete-image/event/${id}?filename=${encodedTarget}`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    console.log(data.error);
                    return data.error;
                } else {
                    console.log('image deleted successfully');
                    return data;
                }
            })
            .catch(err => {
                console.log(err);
                return err;
            });
    }

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;

        setEvent({
            ...eventPayload,
            [name]: value
        });
    }

    const handleCategoryChange = (name: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
            value = 0;
        }

        setSelectedOption(value);

        setEvent({
            ...eventPayload,
            [name]: value
        });
    }

    const setEventCategory = (eventCategory: number) => {
        if (eventCategory > 0) {
            const result = eventCategories.find(({ id }) => id === eventCategory);
            return result;
        }
    }

    const getEventStartDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            return dayjs.utc(eventDateTime);
        }

        return dayjs.utc(new Date());
    }

    const getEventEndDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            return dayjs.utc(eventDateTime);
        }
    }

    const setEventStartDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            let date = dayjs.utc(eventDateTime).locale('zh-cn');

            setEvent({
                ...eventPayload,
                ["start_datetime"]: date.format()
            });
        }
    }

    const setEventEndDateTime = (eventDateTime: string) => {
        if (eventDateTime !== "") {
            let date = dayjs.utc(eventDateTime).locale('zh-cn');

            setEvent({
                ...eventPayload,
                ["end_datetime"]: date.format()
            });
        }
    }

    const handleDescriptionChange = () => {
        setEvent({
            ...eventPayload,
            ['description']: rteRef.current?.editor?.getHTML() || ""
        });
    }

    const handleImagesToDelete = (filename: string) => {
        setImagesToDelete(
            [
                ...imagesToDelete,
                { id: imageId++, filename: filename },
            ]
        );
    }

    const saveContent = (content: string) => {
        setEvent({
            ...eventPayload,
            ['description']: content
        });
    }

    const extensions = useExtensions({
        placeholder: "Add your own content here...",
    });

    const [isEditable, setIsEditable] = useState(true);
    const [showMenuBar, setShowMenuBar] = useState(true);

    const handleNewImageFiles = useCallback(
        (files: File[], insertPosition?: number): void => {
            if (!rteRef.current?.editor) {
                return;
            }

            files.map((file) => {
                console.log('Editor.tsx: ', file);
            });

            const attributesForImageFiles = files.map((file) => ({
                src: URL.createObjectURL(file),
                alt: file.name,
            }));

            insertImages({
                images: attributesForImageFiles,
                editor: rteRef.current.editor,
            });
        },
        [],
    );

    const handleDrop: NonNullable<EditorOptions["editorProps"]["handleDrop"]> =
        useCallback(
            (view, event, _slice, _moved) => {
                if (!(event instanceof DragEvent) || !event.dataTransfer) {
                    return false;
                }

                const imageFiles = fileListToImageFiles(event.dataTransfer.files);
                if (imageFiles.length > 0) {
                    const insertPosition = view.posAtCoords({
                        left: event.clientX,
                        top: event.clientY,
                    })?.pos;

                    handleNewImageFiles(imageFiles, insertPosition);

                    event.preventDefault();
                    return true;
                }

                return false;
            },
            [handleNewImageFiles],
        );

    const handlePaste: NonNullable<EditorOptions["editorProps"]["handlePaste"]> =
        useCallback(
            (_view, event, _slice) => {
                if (!event.clipboardData) {
                    return false;
                }

                const pastedImageFiles = fileListToImageFiles(
                    event.clipboardData.files,
                );
                if (pastedImageFiles.length > 0) {
                    handleNewImageFiles(pastedImageFiles);
                    return true;
                }

                return false;
            },
            [handleNewImageFiles],
        );

    const handleKeyPress: NonNullable<EditorOptions["editorProps"]["handleKeyPress"]> =
        useCallback(
            (_view: EditorView, event: Event) => {
                // Handle key press event
            },
            [],
        );

    const handleKeyDown: NonNullable<EditorOptions["editorProps"]["handleKeyDown"]> =
        useCallback(
            (_view: EditorView, event: any) => {
                if (event.code === "Backspace" || event.code === "Delete") {
                    let img: any = document.querySelector('img[class*="imageSelected"]');

                    if (img !== null) {
                        if (img.src !== 'undefined') {
                            deleteImage(img.src);
                        }
                    }
                }
            },
            [handleImagesToDelete],
        );

    const handleTextInput: NonNullable<EditorOptions["editorProps"]["handleTextInput"]> =
        useCallback(
            (_view: EditorView, from: number, to: number, text: string) => {
                // Handle text input event
            },
            [],
        );

    return (
        <>
            <br />
            <div className="col-md-10 offset-md-1">
                <div><h1>Uredi događaj</h1></div>
                <hr />
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="id" value={eventPayload.id} id="id"></input>
                    <Input
                        title={"Title"}
                        className={"form-control"}
                        type={"text"}
                        name={"title"}
                        value={eventPayload.title}
                        onChange={handleChange("title")}
                        errorDiv={hasError("title") ? "text-danger" : "d-none"}
                        errorMsg={"Please enter a title"}
                    />

                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                            Event Details
                        </label>

                        <Box
                            sx={{
                                "& .ProseMirror": {
                                    "& h1, & h2, & h3, & h4, & h5, & h6": {
                                        scrollMarginTop: showMenuBar ? 50 : 0,
                                    },
                                },
                            }}
                        >
                            <RichTextEditor
                                ref={rteRef}
                                extensions={extensions}
                                content={eventPayload.description}
                                editable={isEditable}
                                editorProps={{
                                    handleKeyPress: handleKeyPress,
                                    handleKeyDown: handleKeyDown,
                                    handleTextInput: handleTextInput,
                                    handleDrop: handleDrop,
                                    handlePaste: handlePaste,
                                }}
                                renderControls={() => <EditorMenuControls />}
                            >
                                {() => (
                                    <>
                                        <LinkBubbleMenu />
                                        <TableBubbleMenu />
                                    </>
                                )}
                            </RichTextEditor>
                        </Box>
                    </div>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DateTimePicker
                            name="startdate"
                            label="Event Start Date & Time"
                            className={"form-control"}
                            value={getEventStartDateTime(eventPayload.start_datetime)}
                            onChange={(newValue: any) => setEventStartDateTime(newValue?.toString())}
                        />
                    </LocalizationProvider>
                    <br /><br />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                        <DateTimePicker
                            name="enddate"
                            label="Event End Date & TIme"
                            className={"form-control"}
                            value={getEventEndDateTime(eventPayload.end_datetime)}
                            onChange={(newValue: any) => setEventEndDateTime(newValue)}
                        />
                    </LocalizationProvider>

                    <br /><br />

                    <Select
                        title={"Event type"}
                        name={"category"}
                        options={eventCategories}
                        value={selectedOption}
                        onChange={handleCategoryChange("category")}
                        placeHolder={"Please choose event category..."}
                        errorDiv={hasError("category") ? "text-danger" : "d-none"}
                        errorMsg={"Please choose"}
                    />

                    <Button type="submit" variant="outlined" onClick={() => {
                        let content = rteRef.current?.editor?.getHTML() || "";
                        saveContent(content);
                    }}
                    >Save</Button>
                </form>
            </div>
            <br />
        </>
    )
}

export default EditEvent;
