interface Config {
  backendUrl: string;
}

interface CustomWindow extends Window {
  env?: {
    REACT_APP_BACKEND?: string;
  };
}

declare let window: CustomWindow;

const config: Config = {
  backendUrl: window.env?.REACT_APP_BACKEND || 'localhost:8080',
};

export default config;
