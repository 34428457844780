import React from 'react';

const AboutPage: React.FC = () => {
    return (
        <>
            <h2>O nama</h2>
        </>
    );
}

export default AboutPage;
