import { useEffect, useState, useContext, ChangeEvent, FormEvent } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "./form/Input";
import Select from "./form/Select";
import TextArea from "./form/TextArea";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/de';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useRef } from "react";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from '@tiptap/extension-text-style'
import { appContext, alertTypes } from '../App';
import config from '../config';

import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    RichTextEditor,
    MenuSelectFontSize,
} from "mui-tiptap";

import Button from '@mui/material/Button';

dayjs.extend(utc);
dayjs.extend(timezone);

interface PostPayload {
    id: number;
    title: string;
    author: number;
    category: number;
    description: string;
    html: string;
    status: number;
    publish_at: string;
    publish_until: string;
}

interface Option {
    id: number;
    value: string | number;
}

const EditPost = () => {
    const rteRef = useRef<any>(null);
    const navigate = useNavigate();
    const { jwtToken } = useOutletContext<{ jwtToken: string }>();

    const context = useContext(appContext);
    if (!context) {
        throw new Error("appContext must be used within a Provider");
    }
    const { toggleAlert, changeAlertType } = context;
    const { setAlertTitle, setAlertMessage } = useOutletContext<{ setAlertTitle: (title: string) => void, setAlertMessage: (message: string) => void }>();

    const [error, setError] = useState<string | null>(null);
    const [errors, setErrors] = useState<string[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [selectedAuthor, setSelectedAuthor] = useState<number | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<number | null>(null);

    const categoryOptions: Option[] = [
        { id: 1, value: 1 },
        { id: 2, value: 2 },
        { id: 3, value: 3 },
    ];

    const authorOptions: Option[] = [
        { id: 1, value: "Tomislav Klasnić (Sundarananda das)" },
        { id: 2, value: "Author 2" },
        { id: 3, value: "Author 3" },
    ];

    const hasError = (key: string) => {
        return errors.indexOf(key) !== -1;
    }

    const [postPayload, setPost] = useState<PostPayload>({
        id: 0,
        title: "",
        author: 0,
        category: 0,
        description: "",
        html: "",
        status: 0,
        publish_at: "",
        publish_until: "",
    });

    const [statusOptions, setStatuses] = useState<Option[]>([]);

    let { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions = {
            method: "GET",
            headers: headers,
            credentials: 'include' as RequestCredentials,
        }

        fetch(`${config.backendUrl}/admin/posts/${id}`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                setPost(data.post);
                setStatusOptions(data.statuses);
                setSelectedCategory(data.post.category);
                setSelectedAuthor(data.post.author);
                setSelectedStatus(data.post.status);
                rteRef.current?.editor?.commands.setContent(data.post.html);
            })
            .catch(err => {
                console.log(err);
            });
    }, [jwtToken, navigate, id]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (jwtToken === "") {
            navigate("/login");
            return;
        }
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`);

        const requestOptions = {
            method: "POST",
            headers: headers,
            credentials: 'include' as RequestCredentials,
            body: JSON.stringify(postPayload),
        }

        fetch(`${config.backendUrl}/admin/update-post`, requestOptions)
            .then((response) => {
                if (response.status === 401) {
                    console.log('Unauthorized');
                    navigate("/login");
                }

                return response.json();
            })
            .then((data) => {
                if (data.error) {
                    changeAlertType(alertTypes.Danger);
                    setAlertTitle("Upozorenje");
                    setAlertMessage(data.message);
                } else {
                    changeAlertType(alertTypes.Success);
                    setAlertTitle("Objava je uspješno spremljena!");
                }

                toggleAlert(true);
            })
            .catch(err => {
                changeAlertType(alertTypes.Danger);
                setAlertTitle("Greška");
                setAlertMessage(err.message);
                toggleAlert(true);
                console.log(err);
            });
    }

    const handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = event.target.value;

        setPost({
            ...postPayload,
            [name]: value
        });
    }

    const handleHtmlChange = () => () => {
        setPost({
            ...postPayload,
            ['html']: rteRef.current?.editor?.getHTML()
        });
    }

    const handleCategoryChange = (name: string) => (event: ChangeEvent<HTMLSelectElement>) => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
            value = 0;
        }

        setSelectedCategory(value);

        setPost({
            ...postPayload,
            [name]: value
        });
    }

    const handleAuthorChange = (name: string) => (event: ChangeEvent<HTMLSelectElement>) => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
            value = 0;
        }

        setSelectedAuthor(value);

        setPost({
            ...postPayload,
            [name]: value
        });
    }

    const handleStatusChange = (name: string) => (event: ChangeEvent<HTMLSelectElement>) => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
            value = 0;
        }

        setSelectedStatus(value);

        setPost({
            ...postPayload,
            [name]: value
        });
    }

    const getPostPublishAtDateTime = (postDateTime: string): Dayjs => {
        if (postDateTime !== "") {
            return dayjs.utc(postDateTime);
        }

        return dayjs.utc(new Date());
    }

    const getPostPublishUntilDateTime = (postDateTime: string): Dayjs => {
        if (postDateTime !== "") {
            return dayjs.utc(postDateTime);
        }

        return dayjs.utc(new Date());
    }

    const setPostPublishAtDateTime = (postDateTime: Dayjs | null) => {
        if (postDateTime !== null) {
            let dateAt = dayjs.utc(postDateTime);

            setPost({
                ...postPayload,
                ["publish_at"]: dateAt.toISOString()
            });
        }
    }

    const setPostPublishUntilDateTime = (postDateTime: Dayjs | null) => {
        if (postDateTime !== null) {
            let dateUntil = dayjs.utc(postDateTime);

            setPost({
                ...postPayload,
                ["publish_until"]: dateUntil.toISOString()
            });
        }
    }

    const setStatusOptions = (statuses: { id: number, name: string }[]) => {
        let updatedStatuses: Option[] = [];
        statuses.map(status => {
            updatedStatuses.push({
                id: status.id,
                value: status.name,
            });
        });

        setStatuses(updatedStatuses.sort((a, b) => a.id - b.id));
    }

    return (
        <>
            <appContext.Provider value={context}>
                <br />
                <div className="col-md-10 offset-md-1">
                    <div>
                        <h1>Uredi objavu</h1>
                    </div>
                    {/* <pre>{JSON.stringify(postPayload, null, 3)}</pre> 
                    <hr />*/}
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" name="id" value={postPayload.id} id="id"></input>
                        <Input
                            title={"Naslov objave"}
                            className={"form-control"}
                            type={"text"}
                            name={"title"}
                            value={postPayload.title}
                            onChange={handleChange("title")}
                            errorDiv={hasError("title") ? "text-danger" : "d-none"}
                            errorMsg={"Molim vas unesite naslov"}
                        />

                        <TextArea
                            title="Bilješke"
                            name={"description"}
                            rows={3}
                            onChange={handleChange("description")} placeholder={""} value={""} errorDiv={""} errorMsg={""}                        />

                        <div className="mb-3">
                            <label htmlFor="html" className="form-label">
                                Objava
                            </label>
                            <RichTextEditor
                                ref={rteRef}
                                extensions={[StarterKit, TextStyle]}
                                content={postPayload.html}
                                onUpdate={handleHtmlChange()}
                                renderControls={() => (
                                    <MenuControlsContainer>
                                        <MenuSelectHeading />
                                        <MenuDivider />
                                        <MenuButtonBold />
                                        <MenuButtonItalic />
                                    </MenuControlsContainer>
                                )}
                            />
                        </div>
                        <br />

                        <Select
                            title={"Kategorija"}
                            name={"category"}
                            options={categoryOptions}
                            value={selectedCategory}
                            onChange={handleCategoryChange("category")}
                            placeHolder={"Odaberite kategoriju objave..."}
                        />

                        <br />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DateTimePicker
                                name="publish_at"
                                label="Objavi ovu objavu na:"
                                className={"form-control"}
                                value={getPostPublishAtDateTime(postPayload.publish_at)}
                                onChange={(newValue) => setPostPublishAtDateTime(newValue)}
                            />
                        </LocalizationProvider>
                        <br /><br />
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DateTimePicker
                                name="publish_until"
                                label="Arhiviraj ovu objavu na:"
                                className={"form-control"}
                                value={getPostPublishUntilDateTime(postPayload.publish_until)}
                                onChange={(newValue) => setPostPublishUntilDateTime(newValue)}
                            />
                        </LocalizationProvider>
                        <br /><br />

                        <Select
                            title={"Autor"}
                            name={"author"}
                            options={authorOptions}
                            value={selectedAuthor}
                            onChange={handleAuthorChange("author")}
                            placeHolder={"Odaberite autora objave..."}
                        />

                        <br /><br />

                        <Select
                            title={"Status"}
                            name={"status"}
                            options={statusOptions}
                            value={selectedStatus}
                            onChange={handleStatusChange("status")}
                            placeHolder={"Odaberite status objave..."}
                        />

                        <Button type="submit" variant="outlined">Spremi promjene</Button>
                    </form>
                </div>
                <br />
            </appContext.Provider>
        </>
    )
}

export default EditPost;
