import React from 'react';

const ShopPage: React.FC = () => {
    return (
        <>
            <h2>Shop</h2>
        </>
    );
}

export default ShopPage;
